export const aboutDemoData = [
    {
        id: '০১',
        title: 'অনুভব',
        demo: true
    },
    {
        id: '০২',
        title: 'আমি আসবো',
        pdf: null,
        demo: true
    },
    {
        id: '০৩',
        title: 'সেই আমার প্রেম',
        pdf: null,
        demo: true
    }
];

export default null;
