export const lyricsDemoData = [
    {
        id: '০১',
        title: 'আবীরের অনুরাগে',
        pdf: null,
        demo: true
    },
    {
        id: '০২',
        title: 'ঘন বরিষায় ধরায় এলো',
        pdf: null,
        demo: true
    },
    {
        id: '০৩',
        title: 'শ্রীরামকৃষ্ণ - বিবেকানন্দ',
        pdf: null,
        demo: true
    },
    {
        id: '০৪',
        title: 'জয়ন্তী মঙ্গলা কালী',
        pdf: null,
        demo: true
    }
];

export default null;
