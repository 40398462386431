import React, { useEffect, useState } from 'react';
import { icons, shapeIcon } from '../../../../../configs/Icons';
import { audioDemoData } from '../../../../../data/audioDemoData';
import { useAudio } from '../../../../context/AudioProvider';
import { useMenu } from '../../../../context/MenuProvider';
import { convertNum } from '../../../../utils/Show';
import getData from '../../../../utils/getData';
import { menuList } from '../../../header/component/menuList';
import AudioTypeTabs from './AudioTypeTabs';

function SectionTitle() {
    return (
        <div className="flex gap-4.5 items-center mb-4">
            <h2 className="text-title-lg">অডিও</h2>

            <div className="w-full h-[22px)]">
                <img className="w-full h-full" src={shapeIcon.shapeTitle} alt="" />
            </div>
        </div>
    );
}

function ContentTitle({ title }) {
    return (
        <div className="flex gap-2.5 items-center justify-center mb-3 md:mb-6.5">
            <img src={shapeIcon.shapeLeft} alt="" />
            <h2 className="text-title-sm">{title}</h2>
            <img src={shapeIcon.shapeRight} alt="" />
        </div>
    );
}

function Tr({ listArr }) {
    const { handlePlayTrack, currentSongIndex, isPlaying } = useAudio();

    if (!listArr?.length) {
        return (
            <tr>
                <td colSpan="3" className="text-left">
                    <span>কোনো তথ্য নেই</span>
                </td>
            </tr>
        );
    }
    return listArr?.map((item, index) => (
        <tr key={item?.id} className={item.demo ? 'placeholder-mode' : ''}>
            <td className="text-center">
                <span>{convertNum(index + 1)}</span>
            </td>
            <td>
                <span>{item?.title}</span>
            </td>

            <td className="text-center">
                {item?.mp3 && (
                    <span>
                        <button
                            type="button"
                            className="w-full flex justify-center"
                            onClick={() => handlePlayTrack(listArr, index)}
                        >
                            <img
                                src={
                                    isPlaying && currentSongIndex === index
                                        ? icons.iconPause
                                        : icons.iconPlay
                                }
                                alt="play"
                                className="w-2.5"
                            />
                        </button>
                    </span>
                )}
            </td>
        </tr>
    ));
}

function Thead() {
    return (
        <thead>
            <tr>
                <th className="text-center w-20">
                    <div className="flex items-center justify-center">
                        <span className="hidden sm:block"> ক্র. নং</span>
                        <span className="block sm:hidden"> নং</span>
                    </div>
                </th>
                <th>গানের কথা</th>
                <th className="pdf">
                    <span>অডিও</span>
                    {/* <div className="flex justify-center">
              <img
                src={icons.iconMusic}
                alt="pdf"
                className="block sm:hidden w-[22px] h-[22px]"
              />
            </div> */}
                </th>
            </tr>
        </thead>
    );
}

function AudioRightCol() {
    const tabs = menuList?.find?.((el) => el.id === 13)?.subMenu;

    const [songTab, setSongTab] = useState(tabs?.[0]?.subMenu?.[0]);

    const { activeMenu } = useMenu();

    const handleSongType = (ev, item) => {
        ev.stopPropagation();

        if (!item?.subMenu) {
            setSongTab(item);
        }
    };

    useEffect(() => {
        if (activeMenu) {
            if (activeMenu?.path === 'audio') {
                setSongTab(activeMenu);
            }
        }
    }, [activeMenu]);

    const listArr = getData(songTab?.type) || audioDemoData;

    return (
        <div className="">
            <div className="md:h-[205px]">
                <SectionTitle />
                <div className="flex gap-3.5 flex-wrap mb-3 md:mb-7.5">
                    {tabs?.map((item) => (
                        <AudioTypeTabs
                            key={item?.id}
                            handleSongType={handleSongType}
                            item={item}
                            songTab={songTab}
                        />
                    ))}
                </div>
                <ContentTitle title={songTab.title} />
            </div>

            <div
                className={`table-wrapper mb-2.5 overflow-auto ${
                    listArr?.length ? 'md:h-[calc(100%_-_205px)]' : 'h-auto'
                }`}
            >
                <table className="table">
                    <Thead />

                    <tbody>
                        <Tr listArr={listArr} />
                    </tbody>
                </table>
            </div>

            {/* {audioDemoData?.length ? (
                <div className="w-full text-right">
                    <button type="button" className="text-title-sm text-lg">
                        আরো দেখুন...
                    </button>
                </div>
            ) : null} */}
        </div>
    );
}

export default AudioRightCol;
