import { createContext, useContext, useMemo, useState } from 'react';

const MenuContext = createContext();

export function useMenu() {
    return useContext(MenuContext);
}

function MenuProvider({ children }) {
    const [activeMenu, setActiveMenu] = useState(0);

    const value = useMemo(() => {
        const handleActiveMenu = (item) => {
            setActiveMenu(item);
        };

        return {
            handleActiveMenu,
            activeMenu
        };
    }, [activeMenu]);

    return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
}

export default MenuProvider;
