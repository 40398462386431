import { Link } from 'react-router-dom';
import { convertNum } from '../../../../utils/Show';

function Tr({ listArr }) {
    if (!listArr?.length) {
        return (
            <tr>
                <td colSpan="3" className="text-left">
                    <span>কোনো তথ্য নেই</span>
                </td>
            </tr>
        );
    }
    return listArr?.map((item, index) => (
        <tr key={item?.id} className={item?.demo ? 'placeholder-mode' : ''}>
            <td className="text-center">
                <span>{convertNum(9 + index)}</span>
            </td>
            <td>
                <span>{item?.title}</span>
            </td>
            <td className="text-center">
                <div className="flex justify-center">
                    {item?.pdf && (
                        <span>
                            <Link
                                target={item?.pdf && '_blank'}
                                to={item?.pdf}
                                className="underline"
                            >
                                দেখুন
                            </Link>
                        </span>
                    )}
                </div>
            </td>
        </tr>
    ));
}

function LetterRightTable({ listArr }) {
    return (
        <div>
            <div className="table-wrapper">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-center w-20">
                                <div className="flex items-center justify-center">
                                    <span> ক্র. নং</span>
                                    {/* <span className="block lg:hidden"> নং</span> */}
                                </div>
                            </th>
                            <th>শিরোনাম</th>
                            <th className="w-[85px]">
                                <span>পিডিএফ</span>
                                {/* <div className="flex justify-center">
                    <img
                      src={icons.iconPdf}
                      alt="pdf"
                      className="block lg:hidden w-[22px] h-[22px]"
                    />
                  </div> */}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <Tr listArr={listArr} />
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default LetterRightTable;
