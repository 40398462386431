import React from 'react';
import { shapeIcon } from '../../../../../configs/Icons';

function InfoRow({ title, value }) {
    return (
        <div className="flex gap-3 justify-between items-center text-dark-gold font-kalpurush text-sm md:text-base leading-normal">
            <p className="w-[35%]">{title}</p>:<p className="flex-1">{value}</p>
        </div>
    );
}

function Title({ title }) {
    return (
        <div className="flex flex-col items-center mb-3.5">
            <h2 className="text-center text-title-sm text-2xl">{title}</h2>
            <img src={shapeIcon.shapeOpera} alt="" />
        </div>
    );
}

function OperaRightCol() {
    return (
        <div className="w-full md:w-[50%] px-5 pb-5 pt-3 overflow-auto primary-scrollbar">
            <Title title="কৈলাসেতে যায়রে উমা" />
            <div className="mb-2.5">
                <InfoRow title="রচনা" value="অধ্যাপক সুশান্ত সরকার" />
                <InfoRow title="সুর ও স্বরলিপি" value="সাধন সরকার" />
                <InfoRow title="প্রচারকাল" value="১১ অক্টোবর, ১৯৭৮ খ্রিস্টাব্দ; রাত ১০ টা" />
                <InfoRow title="" value="১৯ অক্টোবর, ১৯৮০ খ্রিস্টাব্দ; রাত ১০ টা ৩০ মিনিট" />
                <InfoRow title="" value="বাংলাদেশ বেতার, খুলনা" />
            </div>

            <div className="text-center text-dark-gold font-kalpurush text-lg md:text-[22px] leading-7 mb-5">
                (দুর্গা পূজার বিজয়া দশমী উপলক্ষ্যে রচিত)
            </div>

            {/* <div className="flex justify-center">
                <Link
                    target="_blank"
                    to={modifyUrl(
                        'allfiles/201609/1473266599_%E0%A6%95%E0%A7%88%E0%A6%B2%E0%A6%BE%E0%A6%B8%E0%A7%87%E0%A6%A4%E0%A7%87%20%E0%A6%AF%E0%A6%BE%E0%A7%9F%E0%A6%B0%E0%A7%87%20%E0%A6%8A%E0%A6%AE%E0%A6%BE.pdf'
                    )}
                >
                    <ButtonDark title="বিস্তারিত" />
                </Link>
            </div> */}
        </div>
    );
}

export default OperaRightCol;
