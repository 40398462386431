import { menuIcon } from '../../../../configs/Icons';

export const menuList = [
    {
        id: 1,
        title: 'মুল পাতা',
        img1: menuIcon.menu1,
        img2: menuIcon.menu1Active,
        path: 'about'
    },
    {
        id: 6,
        title: 'গানের তালিকা',
        path: 'files/list-of-song-stage.pdf',
        url: 'files/list-of-song-stage.pdf'
        // subMenu: [
        //     {
        //         id: 61,
        //         title: 'পর্যায় ভিত্তিক',
        //         path: 'songList'
        //     },
        //     {
        //         id: 62,
        //         title: 'গীতিকার ভিত্তিক',
        //         path: 'songList'
        //     }
        // ]
    },
    {
        id: 4,
        title: 'সাধন সরকারকে নিয়ে প্রকাশনা ও লেখা',
        img1: menuIcon.menu4,
        img2: menuIcon.menu4Active,
        path: 'aboutWritten',
        subMenu: [
            {
                id: 41,
                pId: 4,
                title: 'মৃত্যু পূর্ববর্তী',
                path: 'aboutWritten',
                type: 'AboutInlife'
            },
            {
                id: 42,
                pId: 4,
                title: 'মৃত্যু পরবর্তী',
                path: 'aboutWritten',
                type: 'AboutAfterlife'
            }
        ]
    },
    {
        id: 5,
        title: 'যোগাযোগ',
        img1: menuIcon.menu5,
        img2: menuIcon.menu5Active,
        path: 'contact'
    },
    {
        id: 8,
        title: 'গান',
        path: 'song',
        subMenu: [
            {
                id: 84,
                title: 'দেশের গান',
                type: 'SongPatriotic',
                path: 'song'
            },
            {
                id: 86,
                title: 'একুশের গান',
                type: 'SongEkushe',
                path: 'song'
            },

            {
                id: 87,
                title: 'গণসঙ্গীত',
                type: 'SongFolk',
                path: 'song'
            },
            {
                id: 83,
                title: 'আধুনিক গান',
                type: 'SongAdhunik',
                path: 'song'
            },
            {
                id: 88,
                title: 'চর্যাপদ',
                type: 'SongChorjapod',
                path: 'song'
            },
            {
                id: 89,
                title: 'রাগপ্রধান গান',
                type: 'SongUcchangoSangitRagprodhangan',
                path: 'song'
            },
            {
                id: 90,
                title: 'বাংলা খেয়াল',
                type: 'SongUcchangoSangitBanglaKhayel',
                path: 'song'
            },
            {
                id: 85,
                title: 'ছড়াগান',
                type: 'SongRhymes',
                path: 'song'
            },
            {
                id: 81,
                title: 'ভক্তি গীতি',
                subMenu: [
                    {
                        id: 811,
                        pId: 81,
                        title: 'দুর্গাপূজা',
                        type: 'SongBhaktigitiDurgapuja',
                        path: 'song'
                    },
                    {
                        id: 812,
                        pId: 81,
                        title: 'কালীপূজা',
                        type: 'SongBhaktigitiKalikapuja',
                        path: 'song'
                    },
                    {
                        id: 813,
                        pId: 81,
                        title: 'সরস্বতীপূজা',
                        type: 'SongBhaktigitiSborisbotipuja',
                        path: 'song'
                    },
                    {
                        id: 814,
                        pId: 81,
                        title: 'দোলপূর্ণিমা',
                        type: 'SongBhaktigitiDolpurnima',
                        path: 'song'
                    },
                    {
                        id: 815,
                        pId: 81,
                        title: 'জন্মাষ্টমী',
                        type: 'SongBhaktigitiJonmastomi',
                        path: 'song'
                    },
                    {
                        id: 816,
                        pId: 81,
                        title: 'রাসপূর্ণিমা',
                        type: 'SongBhaktigitiRashpurnima',
                        path: 'song'
                    },
                    {
                        id: 817,
                        pId: 81,
                        title: 'ঝুলনযাত্রা',
                        type: 'SongBhaktigitiJhulonjatra',
                        path: 'song'
                    }
                ]
            },
            {
                id: 82,
                title: 'বিবিধ গান',
                subMenu: [
                    {
                        id: 821,
                        pId: 82,
                        title: 'কবি রবীন্দ্রনাথ ঠাকুর',
                        type: 'SongOthersRabindranathtagore',
                        path: 'song'
                    },

                    {
                        id: 822,
                        pId: 82,
                        title: 'কবি জীবনানন্দ দাস',
                        type: 'SongOthersJibananandadas',
                        path: 'song'
                    },
                    //   {
                    //     id: 830,
                    //     pId: 82,
                    //     title: "কবি কাজী নজরুল ইসলাম ",
                    //     type: "SongOthersKajiNajrulIslam",
                    //     path: "song",
                    //   },

                    {
                        id: 823,
                        pId: 82,
                        title: 'কবি মাইকেল মধুসূদন দত্ত',
                        type: 'SongOthersModhusudan',
                        path: 'song'
                    },
                    {
                        id: 824,
                        pId: 82,
                        title: 'কবি সুকান্ত ভট্টাচার্য',
                        type: 'SongOthersSukantabhattacharya',
                        path: 'song'
                    },
                    {
                        id: 826,
                        pId: 82,
                        title: 'কবি কৃষ্ণচন্দ্র মজুমদার',
                        type: 'SongOthersKrishnachandramojumder',
                        path: 'song'
                    },
                    {
                        id: 827,
                        pId: 82,
                        title: 'বৌদ্ধপূর্ণিমা',
                        type: 'SongOthersBouddhopurnima',
                        path: 'song'
                    },
                    {
                        id: 828,
                        pId: 82,
                        title: 'বর্ষবরণ',
                        type: 'SongOthersBorshoboron',
                        path: 'song'
                    },
                    {
                        id: 825,
                        pId: 82,
                        title: 'ইসলামী গান',
                        type: 'SongOthersHamdh',
                        path: 'song'
                    },

                    {
                        id: 829,
                        pId: 82,
                        title: 'বিদেশী গান',
                        type: 'SongOthersBideshigan',
                        path: 'song'
                    }
                ]
            }
        ]
    },
    {
        id: 3,
        title: 'সুরারোপিত গানের বই',
        img1: menuIcon.menu3,
        img2: menuIcon.menu3Active,
        path: 'songBook'
    },
    {
        id: 9,
        title: 'গীতিনাট্য ও গীতিআলেখ্য',
        path: 'operaAndLyrics',
        subMenu: [
            {
                id: 91,
                pId: 9,
                title: 'গীতিনাট্য',
                type: 'opera',
                path: 'operaAndLyrics'
            },
            {
                id: 92,
                pId: 9,
                title: 'গীতিআলেখ্য',
                type: 'lyrics',
                path: 'operaAndLyrics'
            }
        ]
    },
    {
        id: 10,
        title: 'সাহিত্য',
        path: 'literature',
        subMenu: [
            {
                id: 101,
                pId: 10,
                title: 'ছড়া',
                type: 'LiteratureChora',
                path: 'literature'
            },
            {
                id: 102,
                pId: 10,
                title: 'কবিতা',
                type: 'LiteraturePoetry',
                path: 'literature'
            },
            {
                id: 103,
                pId: 10,
                title: 'গল্প',
                type: 'LiteratureStory',
                path: 'literature'
            },
            {
                id: 104,
                pId: 10,
                title: 'নাটক',
                type: 'LiteratureDrama',
                path: 'literature'
            }
        ]
    },
    {
        id: 11,
        title: 'সাধন সরকারকে লেখা চিঠি',
        type: 'Letters',
        path: 'letter'
    },
    {
        id: 15,
        title: 'সংগঠন / প্রতিষ্ঠান সংশ্লিষ্টতা',
        type: 'Organization',
        path: 'organization'
    },
    {
        id: 16,
        title: 'প্রাপ্ত সম্মাননা',
        type: 'Honor',
        path: 'honors'
    },
    {
        id: 13,
        title: 'অডিও',
        path: 'audio',
        subMenu: [
            {
                id: 131,
                title: 'নিজ কন্ঠ',
                path: 'audio',
                subMenu: [
                    {
                        id: 1311,
                        pId: 131,
                        title: 'উচ্চাঙ্গ সঙ্গীত',
                        type: 'AudioOwnsingingUcchangosangit',
                        path: 'audio'
                    },
                    {
                        id: 1312,
                        pId: 131,
                        title: 'গণসঙ্গীত',
                        type: 'AudioOwnsingingGonosangit',
                        path: 'audio'
                    },
                    {
                        id: 1313,
                        pId: 131,
                        title: 'অতুল প্রসাদের গান',
                        type: 'AudioOwnsingingAtulproshed',
                        path: 'audio'
                    }
                ]
            },
            {
                id: 132,
                title: 'অন্য কন্ঠ',
                type: 'AudioOthers',
                path: 'audio'
            }
        ]
    },
    {
        id: 14,
        title: 'ভিডিও',
        path: 'video',
        subMenu: [
            {
                id: 141,
                pId: 14,
                title: 'অনুষ্ঠান',
                type: 'VideoOccasion',
                path: 'video'
            },
            {
                id: 142,
                pId: 14,
                title: 'বিশিষ্টজনের বক্তব্য',
                type: 'VideoSpeech',
                path: 'video'
            },
            {
                id: 143,
                pId: 14,
                title: 'প্রামাণ্যচিত্র',
                type: 'VideoDocumentary',
                path: 'video'
            }
        ]
    },
    {
        id: 2,
        title: 'বিবিধ',
        img1: menuIcon.menu2,
        img2: menuIcon.menu2Active,
        type: 'Different',
        path: 'different'
    },

    {
        id: 12,
        title: 'ছবি ঘর',
        path: 'gallery',
        subMenu: [
            {
                id: 121,
                pId: 12,
                title: 'প্রাপ্ত স্বীকৃতি',
                type: 'ImagesRecognition',
                path: 'gallery'
            },
            {
                id: 123,
                pId: 12,
                title: 'ব্যক্তিগত ও পারিবারিক',
                type: 'ImagesFammily',
                path: 'gallery'
            },
            {
                id: 124,
                pId: 12,
                title: 'অনুষ্ঠান',
                type: 'ImagesCeremony',
                path: 'gallery'
            }
        ]
    },
    // {
    //     id: 7,
    //     title: ' সাধন সরকার',
    //     path: 'about'
    // },
    {
        id: 7,
        title: 'পুরনো সংস্করণ',
        path: 'https://v1.sadhansarker.com/',
        v2: true
    }
];

export default null;
