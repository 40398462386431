import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AudioPlayer from './app/audio-player/AudioPlayer';
import HeaderSection from './app/header/HeaderSection';
import HomePage from './app/home/HomePage';
import IndexPage from './app/index-page/IndexPage';

function RouteHandler() {
    return (
        <BrowserRouter>
            <HeaderSection />
            <Routes>
                <Route path="/" element={<IndexPage />} />
                <Route path="/home" element={<HomePage />} />
            </Routes>
            <AudioPlayer />
        </BrowserRouter>
    );
}
export default RouteHandler;
