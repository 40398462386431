import { useEffect, useRef, useState } from 'react';
import { ButtonMenu } from '../../../configs/Buttons';
import { icons } from '../../../configs/Icons';
import Logo from './component/Logo';
import SidebarMenu from './component/SidebarMenu';

function HeaderSectionMobile() {
    const [showSidebar, setShowSidebar] = useState(false);
    const ref = useRef();

    // handler for open sidebar menu
    const handleOpenMenu = () => {
        setShowSidebar((d) => !d);
        document.body.style.overflow = 'hidden';
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current?.contains(event.target)) {
                setShowSidebar(false);
                document.body.style.overflow = 'scroll';
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowSidebar]);

    return (
        <div className=" w-full h-[98px] bg-white mb-3" id="header">
            <div className="w-full h-[98px] fixed top-0 left-0 z-20 bg-[#FFFFFFD9] backdrop-blur-[5px]">
                <div className="max-w-[1440px] h-[calc(100%_-_10px)] mx-auto px-4 2xl:px-0 flex items-center">
                    <div className="flex items-center justify-between  w-[100%]">
                        <div className="w-[180px] xl:w-[250px]">
                            <Logo />
                        </div>

                        <div className="flex justify-end items-center w-[calc(100%_-_210px)]  xl:w-[calc(100%_-_280px)]">
                            <ButtonMenu
                                evOnClick={handleOpenMenu}
                                title={icons.iconMenuInactive}
                                className="py-1.5 px-6.5 w-[44px] h-[44px]"
                            />
                        </div>
                    </div>
                </div>
                <div className="w-full h-[10px]">
                    <img className="w-full h-full object-cover" src={icons.headerShape} alt="" />
                </div>
            </div>

            <SidebarMenu
                contentRef={ref}
                setShowSidebar={setShowSidebar}
                showSidebar={showSidebar}
            />
        </div>
    );
}

export default HeaderSectionMobile;
