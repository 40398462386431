import { useAudio } from '../../../context/AudioProvider';
import { formatSeconds } from '../../../utils/Show';

function AudioPlayerCol3() {
    const { audioRef, currentTime, handleTimeChange } = useAudio();

    return (
        <div className="flex gap-3 xl:gap-5 items-center w-full">
            <span className="font-sweet font-normal text-base text-dark-gold ">
                {audioRef.current?.duration ? formatSeconds(audioRef.current?.duration) : '0:00'}
            </span>
            <input
                type="range"
                value={currentTime}
                max={audioRef.current && audioRef.current.duration ? audioRef.current.duration : ''}
                onChange={handleTimeChange}
                className="w-full border-0 cursor-pointer"
                style={{
                    background: `
                    linear-gradient(
                    to right,
                    #C07F00 0%, #C07F00 ${(
                        (currentTime / audioRef.current?.duration) *
                        100
                    ).toFixed(2)}%, 
                    #FFF5D4 ${((currentTime / audioRef.current?.duration) * 100).toFixed(2)}%, 
                    #FFF5D4 100%
                    )
                    round
                                `
                }}
            />
            <span className="font-sweet font-normal text-base text-dark-gold ">
                {currentTime ? formatSeconds(currentTime) : '0:00'}
            </span>
        </div>
    );
}

export default AudioPlayerCol3;
