import React from 'react';
import { Link } from 'react-router-dom';
import { audioDemoData } from '../../../../../data/audioDemoData';
import { convertNum } from '../../../../utils/Show';
import getData from '../../../../utils/getData';

function Tr({ listArr }) {
    if (!listArr?.length) {
        return (
            <tr>
                <td colSpan="3" className="text-left">
                    <span>কোনো তথ্য নেই</span>
                </td>
            </tr>
        );
    }
    return listArr?.map((item, index) => (
        <tr key={item?.id} className={item.demo ? 'placeholder-mode' : ''}>
            <td className="text-center">
                <span>{convertNum(index + 1)}</span>
            </td>
            <td>
                <span>{item?.title}</span>
            </td>

            <td className="text-center">
                <div className="flex justify-center">
                    {item?.pdf && (
                        <span>
                            <Link
                                target={item?.pdf && '_blank'}
                                to={item?.pdf}
                                className="underline"
                            >
                                দেখুন
                            </Link>
                        </span>
                    )}
                </div>
            </td>
        </tr>
    ));
}

function Thead() {
    return (
        <thead>
            <tr>
                <th className="text-center w-20">
                    <div className="flex items-center justify-center">
                        <span className="hidden sm:block"> ক্র. নং</span>
                        <span className="block sm:hidden"> নং</span>
                    </div>
                </th>
                <th>গানের কথা</th>
                <th className="pdf">
                    <span>পিডিএফ</span>
                    {/* <div className="flex justify-center">
              <img
                src={icons.iconPdf}
                alt="pdf"
                className="block lg:hidden w-[22px] h-[22px]"
              />
            </div> */}
                </th>
            </tr>
        </thead>
    );
}

function DifferentSectionContent() {
    return (
        <div className="">
            <h2 className="text-title-lg  mb-4 md:mb-5 text-center">বিবিধ</h2>

            <div className="table-wrapper mb-2.5">
                <table className="table">
                    <Thead />

                    <tbody>
                        <Tr listArr={getData('Others') || audioDemoData} />
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default DifferentSectionContent;
