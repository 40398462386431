import { createContext, useContext, useMemo, useState } from 'react';
import MessageTemplate from './MessageTemplate';

const TemplateContext = createContext();

export function useTemplate() {
    return useContext(TemplateContext);
}

// const initialState = { type: '', index: 0 };

function TemplateProvider({ children }) {
    const [message, setMessage] = useState(null);

    const value = useMemo(
        () => ({
            message,
            setMessage
        }),
        [message]
    );

    return (
        <TemplateContext.Provider value={value}>
            {children}
            <MessageTemplate message={message} setMessage={setMessage} />
        </TemplateContext.Provider>
    );
}

export default TemplateProvider;
