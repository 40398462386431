import { letterDemoData } from '../../../../data/letterDemoData';
import LayoutProvider from '../../../context/LayoutProvider';
import getData from '../../../utils/getData';
import LetterSectionMobile from './LetterSectionMobile';
import LetterSectionWeb from './LetterSectionWeb';

function LetterSection() {
    return (
        <div className="scroll-m-28">
            <div>
                <div className="section-container-sm mb-4 md:mb-16.5 ">
                    <h2 className="text-title-lg text-center mb-[22px] md:mb-12">
                        সাধন সরকারকে লেখা চিঠি
                    </h2>

                    <LayoutProvider>
                        <LetterSectionWeb
                            layout="default"
                            listArr={getData('Letters') || letterDemoData}
                            letterList={letterDemoData}
                        />
                        <LetterSectionMobile
                            layout={['sm', 'xs', 'xxs']}
                            listArr={getData('Letters') || letterDemoData}
                            letterList={letterDemoData}
                        />
                    </LayoutProvider>
                </div>
            </div>
        </div>
    );
}

export default LetterSection;
