import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonDark } from '../../../../../configs/Buttons';
import { shapeIcon } from '../../../../../configs/Icons';
import { literatureDemoData } from '../../../../../data/literatureDemoData';
import { useMenu } from '../../../../context/MenuProvider';
import { convertNum } from '../../../../utils/Show';
import getData from '../../../../utils/getData';
import { menuList } from '../../../header/component/menuList';

function SectionTitle() {
    return (
        <div className="flex gap-4.5 items-center mb-4 md:mb-5">
            <h2 className="text-title-lg">সাহিত্য</h2>

            <div className="w-full h-[22px)]">
                <img className="w-full h-full" src={shapeIcon.shapeTitle} alt="" />
            </div>
        </div>
    );
}

function ContentTitle({ title }) {
    return (
        <div className="flex gap-2.5 items-center justify-center mb-6.5">
            <img src={shapeIcon.shapeLeft} alt="" />
            <h2 className="text-title-sm">{title}</h2>
            <img src={shapeIcon.shapeRight} alt="" />
        </div>
    );
}

function Tr({ listArr }) {
    if (!listArr?.length) {
        return (
            <tr>
                <td colSpan="3" className="text-left">
                    <span>কোনো তথ্য নেই</span>
                </td>
            </tr>
        );
    }
    return listArr?.map((item, index) => (
        <tr key={item?.id} className={item?.demo ? 'placeholder-mode' : ''}>
            <td className="text-center">
                <span>{convertNum(index + 1)}</span>
            </td>
            <td>
                <span>{item?.title}</span>
            </td>
            <td className="text-center">
                {item?.pdf && (
                    <Link target={item?.pdf && '_blank'} to={item?.pdf} className="underline">
                        দেখুন
                    </Link>
                )}
            </td>
        </tr>
    ));
}

function LiteratureLeftCol() {
    const tabs = menuList?.find?.((el) => el.id === 10)?.subMenu;

    const [tabItem, setTabItem] = useState(tabs[0]);

    const { activeMenu } = useMenu();

    useEffect(() => {
        if (activeMenu) {
            if (activeMenu?.path === 'literature') {
                setTabItem(activeMenu);
            }
        }
    }, [activeMenu]);

    const listArr = getData(tabItem?.type) || literatureDemoData;

    return (
        <div className="">
            <div className="md:h-[225px]">
                <SectionTitle />

                <div className="flex gap-[15px] mb-3 md:mb-7.5">
                    {tabs.map((tab) => (
                        <ButtonDark
                            key={tab?.id}
                            title={tab?.title}
                            evOnClick={() => setTabItem(tab)}
                            active={tabItem?.id === tab?.id}
                        />
                    ))}
                </div>

                <ContentTitle title={tabItem?.title} />
            </div>

            <div
                className={`table-wrapper mb-2.5 overflow-auto ${
                    listArr?.length ? 'md:h-[calc(100%_-_225px)]' : 'h-auto'
                }`}
            >
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-center w-20">
                                <div className="flex items-center justify-center">
                                    <span className="hidden sm:block"> ক্র. নং</span>
                                    <span className="block sm:hidden"> নং</span>
                                </div>
                            </th>
                            <th>শিরোনাম</th>
                            <th className="pdf">
                                <span>পিডিএফ</span>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <Tr listArr={listArr} />
                    </tbody>
                </table>
            </div>

            {/* {literatureList?.length ? (
          <div className="w-full text-right">
            <button type="button" className="text-title-sm text-lg">
              আরো দেখুন...
            </button>
          </div>
        ) : null} */}
        </div>
    );
}

export default LiteratureLeftCol;
