import { Link } from 'react-router-dom';
import { icons } from '../../../../../configs/Icons';
import { useAudio } from '../../../../context/AudioProvider';
import { convertNum } from '../../../../utils/Show';

function Tr({ listArr }) {
    const { handlePlayTrack, currentSongIndex, isPlaying } = useAudio();

    if (!listArr?.length) {
        return (
            <tr>
                <td colSpan="4" className="text-left">
                    <span>কোনো তথ্য নেই</span>
                </td>
            </tr>
        );
    }
    return listArr?.map((item, index, arr) => (
        <tr key={item?.id} className={item.demo ? 'placeholder-mode' : ''}>
            <td className="text-center">
                <span>{convertNum(index + 1)}</span>
            </td>
            <td>
                <span>{item?.title}</span>
            </td>
            <td className="text-center">
                {item?.pdf && (
                    <Link target={item?.pdf && '_blank'} to={item?.pdf} className="underline">
                        দেখুন
                    </Link>
                )}
            </td>
            <td className="text-center">
                {item?.mp3 && (
                    <span>
                        <button
                            type="button"
                            className="w-full flex justify-center"
                            onClick={() => handlePlayTrack(arr, index)}
                        >
                            <img
                                src={
                                    isPlaying && currentSongIndex === index
                                        ? icons.iconPause
                                        : icons.iconPlay
                                }
                                alt="play"
                                className="w-2.5"
                            />
                        </button>
                    </span>
                )}
            </td>
        </tr>
    ));
}

function SongListTable({ listArr }) {
    return (
        <div className="table-wrapper mb-2.5 lg:mb-0 overflow-auto md:h-[calc(100%_-_265px)]">
            <table className="table">
                <thead>
                    <tr>
                        <th className="text-center w-20">
                            <div className="flex items-center justify-center">
                                <span className="hidden sm:block"> ক্র. নং</span>
                                <span className="block sm:hidden"> নং</span>
                            </div>
                        </th>
                        <th>গানের কথা</th>
                        <th className="pdf">
                            <span>পিডিএফ</span>
                            {/* <div className="flex justify-center">
                                <img
                                    src={icons.iconPdf}
                                    alt="pdf"
                                    className="block sm:hidden w-[22px] h-[22px]"
                                />
                            </div> */}
                        </th>
                        <th className="w-12 sm:w-16">
                            <span>অডিও</span>
                            {/* <div className="flex justify-center">
                                <img
                                    src={icons.iconMusic}
                                    alt="music"
                                    className="block sm:hidden w-[22px] h-[22px]"
                                />
                            </div> */}
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <Tr listArr={listArr} />
                </tbody>
            </table>
        </div>
    );
}

export default SongListTable;
