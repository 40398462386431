/* eslint-disable import/no-extraneous-dependencies */
import LazyLoad from 'react-lazy-load';
import sectionImg from '../../../../../assets/images/section-a(e).jpg';

function AboutRightCol() {
    return (
        <div>
            <div className="w-full md:w-[380px] lg:w-[500px] h-full lg:h-[600px]">
                <LazyLoad>
                    <img src={sectionImg} alt="about" className="w-full" />
                </LazyLoad>
            </div>
        </div>
    );
}

export default AboutRightCol;
