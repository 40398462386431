import React from 'react';
import { shapeIcon } from '../../../../../configs/Icons';
import { convertNum } from '../../../../utils/Show';
import getData from '../../../../utils/getData';

function SectionTitle() {
    return (
        <div className="flex gap-4.5 items-center mb-4">
            <h2 className="text-title-lg text-[25px] md:text-[32px] whitespace-nowrap">
                সংগঠন / প্রতিষ্ঠান সংশ্লিষ্টতা
            </h2>

            <div className="w-full h-[22px]">
                <img className="w-full h-[22px] object-cover" src={shapeIcon.shapeTitle} alt="" />
            </div>
        </div>
    );
}

function Tr({ listArr }) {
    console.log(listArr);

    if (!listArr?.length) {
        return (
            <tr>
                <td colSpan="3" className="text-left">
                    <span>কোনো তথ্য নেই</span>
                </td>
            </tr>
        );
    }

    return listArr?.map((item, index) => (
        <tr key={item?.id} className={item.demo ? 'placeholder-mode' : ''}>
            <td className="text-center align-top">
                <span>{item?.id || convertNum(index + 1)}</span>
            </td>
            <td className="align-top">
                <div>
                    <span className="block">
                        <b>{item?.role}</b>
                    </span>
                    <span>{`${item?.organization},${item?.location}`}</span>
                </div>
            </td>
            <td className="align-top">{item?.activities}</td>
        </tr>
    ));
}

function Thead() {
    return (
        <thead>
            <tr>
                <th className="text-center w-[10%] xl:w-[13%]">
                    <div className="flex items-center justify-center">
                        <span className="hidden md:block"> ক্র. নং</span>
                        <span className="block md:hidden"> নং</span>
                    </div>
                </th>
                <th>সংগঠন / প্রতিষ্ঠান</th>
                <th>কৃতকার্য</th>
            </tr>
        </thead>
    );
}

function OrganizationRightCol() {
    const listArr = getData('Organization');

    return (
        <div className="">
            <div className="md:h-[65px]">
                <SectionTitle />
            </div>

            <div
                className={`table-wrapper mb-2.5 overflow-auto ${
                    listArr?.length ? 'md:h-[calc(100%_-_65px)]' : 'h-auto'
                }`}
            >
                <table className="table">
                    <Thead />

                    <tbody>
                        <Tr listArr={listArr} />
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default OrganizationRightCol;
