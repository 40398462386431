import { shapeIcon } from '../../../configs/Icons';

function Separator({ id }) {
    return (
        <div className="w-full h-[15px] md:h-[16px] mb-5 md:mb-16.5 scroll-m-28" id={id}>
            <img className="w-full h-full object-cover" src={shapeIcon.sectionSeparator} alt="" />
        </div>
    );
}

export default Separator;
