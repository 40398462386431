export function Counter({ value, color }) {
    return (
        <div className={`flex items-center rounded w-5 h-7.5 ${color}`}>
            <span className="w-full font-kalpurush text-lg text-white text-center font-normal">
                {value}
            </span>
        </div>
    );
}

function VisitorCounter() {
    // const [visitorData, setVisitorData] = useState({});

    // const { setMessage } = useTemplate();

    // // Get tokenId from localStorage.
    // const tokenFromLocalStorage = localStorage.getItem('token');

    // useEffect(() => {
    //     // Fetch data and set tokenId to localStorage.
    //     handleLoadToken()
    //         .then(({ token }) => {
    //             if (!tokenFromLocalStorage) {
    //                 if (token) {
    //                     localStorage.setItem('token', token);
    //                 }
    //             }
    //         })
    //         .catch((err) => {
    //             setMessage(err.toString());
    //         });
    // }, [setMessage, tokenFromLocalStorage]);

    // useEffect(() => {
    //     // set visitor count data
    //     handleLoadVisitor(tokenFromLocalStorage)
    //         .then(({ data }) => {
    //             setVisitorData(data);
    //         })
    //         .catch((err) => {
    //             setMessage(err.toString());
    //         });
    // }, [setMessage, tokenFromLocalStorage]);

    // console.log(visitorData);

    return (
        <div className="flex gap-1">
            <Counter value={0} color="bg-sky" />
            <Counter value={0} color="bg-sky" />
            <Counter value={0} color="bg-[#F3006B]" />
            <Counter value="১" color="bg-[#F3006B]" />
            <Counter value="৭" color="bg-[#FFAF36]" />
            <Counter value="৭" color="bg-[#FFAF36]" />
            <Counter value="১" color="bg-[#13CC3B]" />
            <Counter value="৯" color="bg-[#13CC3B]" />
            <Counter value="৭" color="bg-[#13CC3B]" />
        </div>
    );
}

export default VisitorCounter;
