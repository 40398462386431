import React, { useEffect } from 'react';
import { templateIcons } from './TemplateIcon';

function MessageTemplate({ message, setMessage }) {
    const handleClose = () => {
        setMessage(null);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setMessage(null);
        }, message?.timeout || 8000);

        return () => {
            clearTimeout(timer);
        };
    }, [message, setMessage]);

    if (!message) {
        return null;
    }

    return (
        <div className="fixed bg-primary left-3 bottom-3 w-80 rounded-md text-white p-[10px] min-[50px] z-[2001] flex justify-between items-center">
            <span className="font-light text-sm">{message?.text || message}</span>
            <button
                type="button"
                onClick={handleClose}
                className="border-0 border-solid text-white cursor-pointer opacity-80 hover:opacity-100"
            >
                <img src={templateIcons.tClose} alt="" className="w-4" />
            </button>
        </div>
    );
}

export default MessageTemplate;
