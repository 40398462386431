import { icons } from '../../../configs/Icons';
import Logo from './component/Logo';
import Menu from './component/Menu';

function HeaderSectionWeb() {
    return (
        <div className=" w-full h-[75px] bg-white mb-3" id="header">
            <div className="w-full h-[75px] fixed top-0 left-0 z-20 bg-[#FFFFFFD9] backdrop-blur-[5px]">
                <div className="max-w-[1440px] h-[calc(100%_-_16px)] mx-auto px-4 2xl:px-0 flex items-center">
                    <div className="flex items-center justify-between w-[100%]">
                        <div className="w-[180px] xl:w-[250px]">
                            <Logo />
                        </div>
                        <div className="flex justify-end items-stretch w-[calc(100%_-_210px)]  xl:w-[calc(100%_-_280px)]">
                            <Menu />
                        </div>
                    </div>
                </div>
                <div className="w-full h-[16px]">
                    <img className="w-full h-full object-cover" src={icons.headerShape} alt="" />
                </div>
            </div>
        </div>
    );
}

export default HeaderSectionWeb;
