/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { audioPlayerIcon } from '../../../../configs/Icons';
import { useAudio } from '../../../context/AudioProvider';

function AudioPlayerCol1() {
    const {
        playPauseHandler,
        nextSongHandler,
        prevSongHandler,
        repeatHandler,
        volumeChangeHandler,
        timeUpdateHandler,
        handleTimeChange,
        errorHandler,
        volume,
        currentTime,
        repeat,
        isPlaying,
        audioRef,
        currentSong
    } = useAudio();

    return (
        <div className="flex gap-3 xl:gap-5 items-center ">
            <audio
                ref={audioRef}
                src={currentSong}
                onTimeUpdate={timeUpdateHandler}
                onEnded={nextSongHandler}
                onError={errorHandler}
                loop={repeat}
                preload="none" // Important for iOS
            >
                <track kind="captions" srcLang="en" label="English" />
            </audio>
            <button type="button" className="w-7 h-7">
                <img src={audioPlayerIcon.iconShuffle} alt="shuffle" className="w-full h-full" />
            </button>

            <button type="button" className="w-10 h-10" onClick={prevSongHandler}>
                <img src={audioPlayerIcon.iconPrev} alt="prev" className="w-full h-full" />
            </button>

            <button
                type="button"
                className="w-[45px] lg:w-[60px]"
                onClick={() => playPauseHandler()}
            >
                <img
                    src={isPlaying ? audioPlayerIcon.iconPauseLg : audioPlayerIcon.iconPlayLg}
                    alt="play"
                    className="w-full h-full"
                />
            </button>

            <button type="button" className="w-10 h-10" onClick={nextSongHandler}>
                <img src={audioPlayerIcon.iconNext} alt="next" className="w-full h-full" />
            </button>

            <button type="button" className="w-7 h-7" onClick={repeatHandler}>
                <img
                    src={repeat ? audioPlayerIcon.iconRepeatActive : audioPlayerIcon.iconRepeat}
                    alt="repeat"
                    className="w-full h-full"
                />
            </button>
        </div>
    );
}

export default AudioPlayerCol1;
