import React, { useEffect, useState } from 'react';
import { ButtonDark } from '../../../../../configs/Buttons';
import { shapeIcon } from '../../../../../configs/Icons';
import { videoDemoData } from '../../../../../data/videoDemoData';
import { useAudio } from '../../../../context/AudioProvider';
import { useMenu } from '../../../../context/MenuProvider';
import { convertNum } from '../../../../utils/Show';
import getData from '../../../../utils/getData';
import { menuList } from '../../../header/component/menuList';
import YoutubePlayer from './YoutubePlayer';

function ContentTitle({ title }) {
    return (
        <div className="flex gap-2.5 items-center justify-center mb-3 md:mb-6.5">
            <img src={shapeIcon.shapeLeft} alt="" />
            <h2 className="text-title-sm">{title}</h2>
            <img src={shapeIcon.shapeRight} alt="" />
        </div>
    );
}

function Tr({ listArr, setShowVideo }) {
    const { handleCloseAudioPlayer } = useAudio();

    const handleButtonClick = (id) => {
        // Set the video ID and toggle the flag to display the video
        // setVideoId(link);
        setShowVideo((prevDt) => ({
            ...prevDt,
            mode: true,
            id
        }));
        handleCloseAudioPlayer();
    };

    if (!listArr?.length) {
        return (
            <tr>
                <td colSpan="3" className="text-left">
                    <span>কোনো তথ্য নেই</span>
                </td>
            </tr>
        );
    }
    return listArr?.map((item, index) => {
        const splitLink = item?.youtubeLink?.split('/');

        return (
            <tr key={item?.id} className={item.demo ? 'placeholder-mode' : ''}>
                <td className="text-center">
                    <span>{convertNum(index + 1)}</span>
                </td>
                <td>
                    <span>{item?.title}</span>
                </td>

                <td className="text-center">
                    {item?.youtubeLink && (
                        <span className="flex justify-center">
                            <button
                                type="button"
                                onClick={() => handleButtonClick(splitLink[splitLink?.length - 1])}
                                className="underline"
                            >
                                দেখুন ও শুনুন
                            </button>
                        </span>
                    )}
                </td>
            </tr>
        );
    });
}

function Thead() {
    return (
        <thead>
            <tr>
                <th className="text-center w-20">
                    <div className="flex items-center justify-center">
                        <span className="hidden sm:block"> ক্র. নং</span>
                        <span className="block sm:hidden"> নং</span>
                    </div>
                </th>
                <th>বিবরণ</th>
                <th className="w-[90px] sm:w-[120px]">
                    <span>ভিডিও</span>
                    {/* <div className="flex justify-center">
                        <img
                            src={icons.iconYoutubeSm}
                            alt="pdf"
                            className="block sm:hidden w-[22px] h-[22px]"
                        />
                    </div> */}
                </th>
            </tr>
        </thead>
    );
}

function VideoContent() {
    const tabs = menuList?.find?.((el) => el.id === 14)?.subMenu;

    const [tabItem, setTabItem] = useState(tabs?.[0]);
    const [showVideo, setShowVideo] = useState({
        mode: false,
        id: ''
    }); // Flag to determine if the video should be displayed

    const { activeMenu } = useMenu();
    const { mode, id } = showVideo;

    useEffect(() => {
        if (activeMenu) {
            if (activeMenu?.path === 'video') {
                setTabItem(activeMenu);
            }
        }
    }, [activeMenu]);

    return (
        <div className="">
            <h2 className="text-title-lg  mb-4 md:mb-6 text-center">ভিডিও</h2>

            <div className="flex gap-3.5 justify-center mb-3.5 md:mb-7.5">
                {tabs.map((tab) => (
                    <ButtonDark
                        key={tab?.id}
                        title={tab?.title}
                        evOnClick={() => setTabItem(tab)}
                        active={tabItem?.id === tab?.id}
                    />
                ))}
            </div>

            <ContentTitle title={tabItem?.title} />

            {tabItem?.type !== 'VideoDocumentary' ? (
                <div className="table-wrapper mb-2.5">
                    <table className="table">
                        <Thead />

                        <tbody>
                            <Tr
                                listArr={getData(tabItem?.type) || videoDemoData}
                                setShowVideo={setShowVideo}
                            />
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="">
                    <div className="w-full h-96">
                        <iframe
                            src="https://www.youtube.com/embed/x_CAV7m22WM"
                            frameBorder="0"
                            allowFullScreen
                            title="YouTube Video"
                            className="w-full h-full bg-black"
                        />
                    </div>
                </div>
            )}

            {mode && <YoutubePlayer closeClick={() => setShowVideo({ mode: false })} id={id} />}
        </div>
    );
}

export default VideoContent;
