// API
const api = 'https://files.bikiran.com';

// Template
const tClose = `${api}/assets/images/template/close.svg`;
const tConfirm = `${api}/assets/images/template/confirm.svg`;

export const templateIcons = {
    tClose,
    tConfirm
};

export default null;
