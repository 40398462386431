export const songListDemoData = [
    {
        id: '০১',
        title: 'শরতের এই আঙ্গিনাতে রূপের কিরণে শরতের এই আঙ্গিনাতে রূপের কিরণে',
        demo: true
    },
    {
        id: '০২',
        title: 'রাতের কালো ঘুচায় যেথা দিনের রবি রাতের কালো ঘুচায় যেথা দিনের রবি',
        demo: true
    }
];

export default null;
