import { Link } from 'react-router-dom';
import { convertNum } from '../../../utils/Show';

function LetterSectionMobile({ listArr }) {
    return (
        <div>
            <div className="table-wrapper">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-center w-[10%]">নং</th>
                            <th>শিরোনাম</th>
                            {/* <th className="w-[15%]">
                                <div className="flex justify-center">
                                    <img
                                        src={icons.iconPdf}
                                        alt="pdf"
                                        className="w-[22px] h-[22px]"
                                    />
                                </div>
                            </th> */}
                            <th className="pdf">
                                <span>পিডিএফ</span>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {listArr?.map((item, index) => (
                            <tr key={item?.id} className={item?.demo ? 'placeholder-mode' : ''}>
                                <td className="text-center">
                                    <span>{convertNum(index + 1)}</span>
                                </td>
                                <td>
                                    <span>{item?.title}</span>
                                </td>
                                <td className="text-center">
                                    {item?.pdf && (
                                        <Link
                                            target={item?.pdf && '_blank'}
                                            to={item?.pdf}
                                            className="underline"
                                        >
                                            দেখুন
                                        </Link>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default LetterSectionMobile;
