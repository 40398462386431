import { icons } from './Icons';

export function ButtonDark({ evOnClick, title, className, disabled, active }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`${active ? 'button-light' : 'button-dark'} ${className}`}
            disabled={disabled}
        >
            <span>{title}</span>
        </button>
    );
}

export function ButtonDarkV2({ evOnClick, title, children, className, disabled, active }) {
    return (
        <div
            role="button"
            tabIndex={-1}
            onClick={evOnClick}
            type="button"
            className={`relative group/inst ${
                active ? 'button-light' : 'button-dark'
            } ${className}`}
            disabled={disabled}
        >
            <span>{title}</span>
            {children}
        </div>
    );
}

export function ButtonLight({ evOnClick, title, className, disabled }) {
    return (
        <button
            onClick={evOnClick}
            type="button"
            className={`button-light ${className}`}
            disabled={disabled}
        >
            <span>{title}</span>
        </button>
    );
}

export function ButtonMenu({ evOnClick, className, active, disabled, children }) {
    return (
        <div
            role="button"
            tabIndex={-1}
            onClick={evOnClick}
            className={`relative group group/inst inline-block lg:h-full lg:w-[82px] button-light ${
                active ? 'lg:button-light' : 'lg:button-dark'
            } ${className}`}
            disabled={disabled}
        >
            <img
                src={icons.iconMenuInactive}
                alt="menu"
                className="w-[32px] h-[32px] block transition-opacity absolute top-[50%] left-[50%] lg:opacity-1 translate-x-[-50%] translate-y-[-50%]"
            />
            <img
                src={icons.iconMenuActive}
                alt="menu"
                className="w-[32px] h-[32px] block transition-opacity absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] lg:opacity-0 group-hover:opacity-100"
            />

            {children}
        </div>
    );
}

function Buttons() {
    return null;
}

export default Buttons;
