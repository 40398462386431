import HonorsLeftCol from './components/HonorsLeftCol';
import HonorsRightCol from './components/HonorsRightCol';

function HonorsSection() {
    return (
        <div>
            <div className="section-container-sm flex flex-col-reverse xl:flex-row xl:gap-14 justify-between xl:max-h-[600px] overflow-hidden">
                <HonorsLeftCol />

                <HonorsRightCol />
            </div>
        </div>
    );
}

export default HonorsSection;
