import { useEffect, useState } from 'react';
import { icons } from '../../../../configs/Icons';
import { useMenu } from '../../../context/MenuProvider';
import VisitorCounter from '../../../features/visitor-counter/VisitorCounter';
import { scrollToSection } from '../../../utils/Show';
import modifyUrl from '../../../utils/modifyUrl';
import Logo from './Logo';
import { menuList } from './menuList';

function Arrow({ active }) {
    return (
        <img
            src={icons.iconMenuArrow}
            alt="arrow"
            className={`w-6 ${active ? 'rotate-180' : ''}`}
        />
    );
}

function SubMenu({
    show,
    handleActiveMenu,
    setShowSidebar,
    subMenuId,
    setOpenSubMenu,
    data,
    line
}) {
    // const [subMenuId, setOpenSubMenu] = useState(0);

    const handleOpenSubMenu = (id) => {
        if (subMenuId === id) {
            setOpenSubMenu(0);
        } else {
            setOpenSubMenu(id);
        }
    };

    const handleMenuClick = (item) => {
        handleActiveMenu(item);
        if (item?.subMenu) {
            handleOpenSubMenu(item?.id);
        } else {
            document.body.style.overflowY = 'scroll';
            setShowSidebar((d) => !d);
            scrollToSection(item?.path);
        }
    };

    return data?.map((item) => (
        <div
            key={item?.id}
            className={`font-sweet text-sm md:text-lg text-dark-gold transition-all  ${
                line ? 'sidebar-submenu' : ''
            } ${show ? 'h-auto opacity-100 mb-5 ' : 'h-0  opacity-0'}`}
        >
            <button
                type="button"
                onClick={() => handleMenuClick(item)}
                className={`w-full flex justify-between items-center  ${
                    subMenuId === item?.id ? 'mb-3' : ''
                }`}
            >
                <h3 className="flex-1 text-left">{item?.title}</h3>

                {item?.subMenu && <Arrow active={subMenuId === item?.id} />}
            </button>
            <SubMenu
                show={subMenuId === item?.id}
                handleActiveMenu={handleActiveMenu}
                setShowSidebar={setShowSidebar}
                subMenuId={subMenuId}
                setOpenSubMenu={setOpenSubMenu}
                data={item?.subMenu}
                line
            />
        </div>
    ));
}

function Header() {
    return (
        <div className="w-full h-[98px] bg-[#FFF] mb-6">
            <div className=" h-[calc(100%_-_10px)] mx-auto px-4 2xl:px-0 flex items-center">
                <div className="flex items-center justify-between  w-[100%]">
                    <div className="w-[106px]">
                        <Logo />
                    </div>
                </div>
            </div>
            <div className="w-full h-[10px]">
                <img className="w-full h-full object-cover" src={icons.headerShape} alt="" />
            </div>
        </div>
    );
}

function SidebarMenu({ contentRef, setShowSidebar, showSidebar }) {
    const [menuId, setOpenMenu] = useState(0);
    const [subMenuId, setOpenSubMenu] = useState(0);

    const { handleActiveMenu } = useMenu();

    // set state of menu open/close
    const handleOpenMenu = (id) => {
        if (menuId === id) {
            setOpenMenu(0);
        } else {
            setOpenMenu(id);
        }
    };

    // handler for menu click
    const handleMenuClick = (ev, item) => {
        // handleActiveMenu(item);
        if (item.url) {
            window.open(modifyUrl(item.url), '_blank');
            return;
        }
        if (item?.v2) {
            window.open(item?.v2, '_blank');
            return;
        }
        if (item?.subMenu) {
            handleOpenMenu(item?.id);
        } else {
            document.body.style.overflowY = 'scroll';
            setShowSidebar((d) => !d);
            scrollToSection(item?.path);
        }
    };

    // close opened submenu when sidebar close
    useEffect(() => {
        if (!showSidebar) {
            setOpenMenu(0);
            setOpenSubMenu(0);
        }
    }, [showSidebar]);

    return (
        <div
            className={`w-full h-full bg-[rgba(31,13,0,0.30)] shadow-[3px_0px_50px_0px_rgba(74,41,18,0.05)] fixed top-0 z-20 transition-all ease-in-out duration-300 ${
                showSidebar ? 'left-0 ' : '-left-full'
            }`}
        >
            <div ref={contentRef} className="w-[80%] h-full bg-[#fff]">
                <Header />

                <ul className="pl-3.5 pr-8 h-[calc(100%_-_121px_-_128px)] overflow-y-scroll">
                    {menuList?.map((item) => {
                        const isActive = menuId === item?.id;

                        return (
                            <li
                                key={item?.id}
                                className="mb-5 font-sweet text-sm md:text-lg text-dark-gold"
                            >
                                <button
                                    type="button"
                                    onClick={(ev) => handleMenuClick(ev, item)}
                                    className={`w-full flex justify-between items-center ${
                                        isActive ? 'mb-5' : ''
                                    }`}
                                >
                                    <h3 className="flex-1 text-left">{item?.title}</h3>

                                    {item?.subMenu && <Arrow active={isActive} />}
                                </button>
                                <div className="pl-3.5 pr-8 h-[calc(100%_-_121px_-_128px)] overflow-y-scroll transition-all">
                                    <SubMenu
                                        data={item?.subMenu}
                                        show={isActive}
                                        handleOpenMenu={handleOpenMenu}
                                        setShowSidebar={setShowSidebar}
                                        subMenuId={subMenuId}
                                        setOpenSubMenu={setOpenSubMenu}
                                        handleActiveMenu={handleActiveMenu}
                                    />
                                </div>
                            </li>
                        );
                    })}
                </ul>

                <div className="h-32 flex flex-col justify-center items-center bg-[#fff]">
                    <p className="font-sweet text-lg text-dark-gold leading-7 font-normal mb-3">
                        দর্শনার্থীর সংখ্যা:
                    </p>

                    <VisitorCounter />
                </div>
            </div>
        </div>
    );
}

export default SidebarMenu;
