import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ConfirmTemplate from '../features/template/ConfirmTemplate';
import modifyUrl from '../utils/modifyUrl';

const AudioContext = createContext();

export function useAudio() {
    return useContext(AudioContext);
}

const initialState = {
    isPlaying: false,
    volume: 0.5,
    isMuted: false,
    currentTime: 0,
    repeat: false,
    currentSongIndex: 0,
    audioTrack: [],
    isError: 0,
    nextSong: false
};

function AudioProvider({ children }) {
    const [showAudioPlayer, setShowAudioPlayer] = useState(false);
    const [audioController, setAudioController] = useState(initialState);
    const [confirm, setConfirm] = useState(null);

    // const { setMessage } = useTemplate();

    // destructure audioController
    const {
        isPlaying,
        volume,
        isMuted,
        currentTime,
        repeat,
        currentSongIndex,
        songArr,
        audioTrack,
        isError,
        nextSong
    } = audioController || {};

    const audioRef = useRef();
    const currentSong = audioTrack[currentSongIndex] || null;

    // update setAudioController state

    const updateAudioController = (newData) => {
        setAudioController((prevData) => ({ ...prevData, ...newData }));
    };

    // update audioController when audioTrack changes
    useEffect(() => {
        if (audioRef.current) {
            if (isPlaying) {
                if (audioTrack?.length) {
                    setShowAudioPlayer(true);
                }
                try {
                    audioRef.current.play().catch(() => {
                        // setMessage('Failed to load because no supported source was found.');
                        updateAudioController({
                            isPlaying: false,
                            currentTime: 0
                        });
                    });
                } catch (error) {
                    console.error('Error while playing audio:', error);
                }
            } else {
                audioRef.current.pause();
            }
        }
    }, [isPlaying, currentSong, audioTrack.length]);

    // when audio player is closed, pause audio and set current time to 0
    useEffect(() => {
        if (!showAudioPlayer) {
            updateAudioController({
                isPlaying: false,
                currentTime: 0
            });

            if (audioRef.current) {
                audioRef.current.currentTime = 0;
            }
        }
    }, [showAudioPlayer]);

    // id isMuted is true, mute audio
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.muted = isMuted;
        }
    }, [isMuted]);

    // useEffect for showing confirm template when error occurs
    useEffect(() => {
        if (isError) {
            setConfirm({
                textCancel: 'না',
                textAction: 'হ্যাঁ',
                text: 'গানটা খুঁজে পাওয়া যায়নি। আপনি কি পরবর্তী গান শুনতে চান ?',
                // when click "হ্যাঁ"
                clickAction: () => {
                    // close confirm template
                    setConfirm(null);
                    // play next song
                    if (audioTrack?.length) {
                        updateAudioController({
                            currentSongIndex: (currentSongIndex + 1) % audioTrack.length,
                            isPlaying: true,
                            isError: 0
                        });
                    }
                },
                // when click "না"
                clickCancel: () => {
                    updateAudioController({
                        isError: 0
                    });
                }
            });
        }
    }, [audioTrack.length, currentSongIndex, isError]);

    const value = useMemo(() => {
        // handler for closing audio player
        const handleCloseAudioPlayer = () => {
            setShowAudioPlayer(false);
        };

        // handler for playing and pausing audio
        const playPauseHandler = () => {
            if (audioTrack?.length) {
                updateAudioController({
                    isPlaying: !isPlaying
                });
            }
        };

        // handler for playing audio
        const handlePlayTrack = (arr, index) => {
            updateAudioController({
                currentSongIndex: index,
                audioTrack: arr.map((item) => item?.mp3),
                songArr: arr,
                isPlaying: !isPlaying,
                isError: isError && 0
            });

            if (index === currentSongIndex) {
                updateAudioController({
                    isPlaying: !isPlaying,
                    isError: isError && 0
                });
            } else {
                updateAudioController({
                    isPlaying: true,
                    isError: isError && 0
                });
            }
        };

        // handler for playing next audio
        const nextSongHandler = () => {
            if (audioTrack?.length) {
                updateAudioController({
                    currentSongIndex: (currentSongIndex + 1) % audioTrack.length,
                    isPlaying: true
                });
            }
        };

        // handler for playing previous audio
        const prevSongHandler = () => {
            if (audioTrack?.length) {
                updateAudioController({
                    currentSongIndex:
                        (currentSongIndex - 1 + audioTrack.length) % audioTrack.length,
                    isPlaying: true
                });
            }
        };

        // error handler
        const errorHandler = (event) => {
            event.preventDefault();
            updateAudioController({
                isPlaying: false,
                currentTime: 0,
                isError: 1
            });
            if (nextSong) {
                nextSongHandler();
            }
        };

        // handler for repeat audio when it ends
        const repeatHandler = () => {
            if (audioTrack?.length) {
                updateAudioController({
                    repeat: !repeat
                });
            }
        };

        // handler for changing volume
        const volumeChangeHandler = (event) => {
            if (audioTrack?.length) {
                const newVolume = parseFloat(event.target.value);

                updateAudioController({
                    volume: newVolume
                });
                audioRef.current.volume = newVolume;
            }
        };

        // handler for getting current audio time
        const timeUpdateHandler = () => {
            if (audioTrack?.length) {
                updateAudioController({
                    currentTime: audioRef.current.currentTime
                });
            }
        };

        // handler for seeking time
        const handleTimeChange = (e) => {
            if (audioTrack?.length) {
                const newTime = e.target.value;
                audioRef.current.currentTime = newTime;
            }
        };

        // handler for muting audio
        const muteHandler = () => {
            if (audioTrack?.length) {
                updateAudioController({
                    isMuted: !isMuted
                });
            }
        };

        return {
            showAudioPlayer,
            handlePlayTrack,
            handleCloseAudioPlayer,
            playPauseHandler,
            nextSongHandler,
            prevSongHandler,
            repeatHandler,
            volumeChangeHandler,
            timeUpdateHandler,
            muteHandler,
            handleTimeChange,
            volume,
            currentTime,
            repeat,
            isPlaying,
            currentSong: modifyUrl(currentSong),
            audioRef,
            isMuted,
            currentSongIndex,
            currentSongTitle: songArr?.[currentSongIndex]?.title,
            errorHandler,
            isError
        };
    }, [
        audioTrack.length,
        currentSong,
        currentSongIndex,
        currentTime,
        isError,
        isMuted,
        isPlaying,
        nextSong,
        repeat,
        showAudioPlayer,
        songArr,
        volume
    ]);

    return (
        <AudioContext.Provider value={value}>
            {children}
            <ConfirmTemplate confirm={confirm} setConfirm={setConfirm} />
        </AudioContext.Provider>
    );
}

export default AudioProvider;
