import React from 'react';
import Separator from '../../features/separator/Separator';
import FooterSection from '../footer/FooterSection';
import LyricsAndOperaSection from './LyricsAndOpera/LyricsAndOperaSection';
import AboutWrittenSection from './about-written/AboutWrittenSection';
import AboutSection from './about/AboutSection';
import AudioSection from './audio/AudioSection';
import DifferentSection from './different/DifferentSection';
import HonorsSection from './honors/HonorsSection';
import ImageGallerySection from './image-gallery/ImageGallerySection';
import LetterSection from './letter/LetterSection';
import LiteratureSection from './literature/LiteratureSection';
import OrganizationSection from './organization/OrganizationSection';
import SongBookSection from './song-book/SongBookSection';
import SongSection from './song/SongSection';
import VideoSection from './video/VideoSection';

function HomePage() {
    return (
        <div className="w-full bg-songs-shape absolute -z-40">
            {/* <HeroSection /> */}

            <AboutSection />

            <Separator id="song" />
            <SongSection />

            <Separator id="songBook" />
            <SongBookSection />

            <Separator id="operaAndLyrics" />
            <LyricsAndOperaSection />

            <Separator id="literature" />
            <LiteratureSection />

            <Separator id="aboutWritten" />
            <AboutWrittenSection />

            <Separator id="letter" />
            <LetterSection />

            <Separator id="organization" />
            <OrganizationSection />

            <Separator id="honors" />
            <HonorsSection />

            <Separator id="audio" />

            <AudioSection />

            <Separator id="video" />

            <VideoSection />

            <Separator id="different" />
            <DifferentSection />

            <Separator id="gallery" />
            <ImageGallerySection />

            <FooterSection />
        </div>
    );
}

export default HomePage;
