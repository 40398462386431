import { Link } from 'react-router-dom';

function IndexPage() {
    return (
        <div className=" w-full h-screen flex justify-center">
            <div className="w-[862px] h-full bg-index-sm sm:bg-index-md lg:bg-index-lg bg-no-repeat bg-cover sm:bg-contain 2xl:bg-cover bg-center flex justify-center items-center">
                <div className="flex gap-6 flex-col justify-center border border-[ffffff4d] bg-[rgba(126,180,210,0.35)] backdrop-blur-[5px] rounded-[20px] py-4 px-4 2xl:py-5 2xl:px-5 max-w-[270px] sm:max-w-[215px] 2xl:max-w-[328px] -mt-72 sm:mt-0">
                    <p className="font-normal font-kalpurush text-sm 2xl:text-lg text-[#FFF] text-center">
                        সঙ্গীতাচার্য সাধন সরকার এর সকল সৃষ্টিকর্ম সংরক্ষণ করে সংস্কৃতি প্রেমী সকলের
                        সামনে উপস্থাপনের মাধ্যমে প্রচার করাই এই ওয়েব সাইটের উদ্দেশ্য
                    </p>

                    <div className="w-full flex justify-center ">
                        <Link to="/home">
                            <button
                                onClick={null}
                                type="button"
                                className="button-dark max-w-[112px] px-5 py-1.5"
                            >
                                <p className="font-sweet text-[#ffd95a] text-[15px] md:text-sm 2xl:text-lg 2xl:leading-8 font-normal">
                                    মুলপাতা
                                </p>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IndexPage;
