import { icons } from '../../../../configs/Icons';

function Logo() {
    return (
        <div className="w-[130px]">
            <img className="w-full" src={icons.iconLogo} alt="" />
        </div>
    );
}

export default Logo;
