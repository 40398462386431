import LiteratureLeftCol from './components/LiteratureLeftCol';
import LiteratureRightCol from './components/LiteratureRightCol';

function LiteratureSection() {
    return (
        <div className="scroll-m-28">
            <div className="section-container-sm">
                <div className="flex flex-col-reverse xl:flex-row xl:gap-14 xl:max-h-[600px] overflow-hidden">
                    <LiteratureLeftCol />
                    <LiteratureRightCol />
                </div>
            </div>
        </div>
    );
}

export default LiteratureSection;
