export const audioDemoData = [
    {
        id: '০১',
        title: 'দেশ',
        path: 'https://www.sadhansarker.com/allfiles/201608/1471862597_%E0%A6%B8%E0%A7%87%20%E0%A6%A1%E0%A6%BE%E0%A6%95%E0%A7%87%20%E0%A6%86%E0%A6%AE%E0%A6%BE%E0%A6%B0%E0%A7%87%20%E0%A6%AC%E0%A6%BF%E0%A6%A8%E0%A6%BE%20%E0%A6%B8%E0%A7%87%20%E0%A6%B8%E0%A6%96%E0%A6%BE%E0%A6%B0%E0%A7%87-%E0%A6%85%E0%A6%A4%E0%A7%81%E0%A6%B2%20%E0%A6%AA%E0%A7%8D%E0%A6%B0%E0%A6%B8%E0%A6%BE%E0%A6%A6.mp3',
        demo: true
    },
    {
        id: '০২',
        title: 'বৃন্দাবনী সারং',
        path: 'https://www.sadhansarker.com/allfiles/201606/1467248959_%E0%A6%97%E0%A7%87%E0%A6%B0%E0%A6%BF%E0%A6%B2%E0%A6%BE%20%E0%A6%AC%E0%A6%BE%E0%A6%82%E0%A6%B2%E0%A6%BE%E0%A6%B0.mp3',
        demo: true
    },
    {
        id: '০৩',
        title: 'হংসধ্বনি [ত্রুটিযুক্ত]',
        path: 'https://www.sadhansarker.com/allfiles/201606/1467248344_%E0%A6%86%E0%A6%AE%E0%A6%BE%E0%A6%A6%E0%A7%87%E0%A6%B0%20%E0%A6%B8%E0%A6%82%E0%A6%97%E0%A7%8D%E0%A6%B0%E0%A6%BE%E0%A6%AE%20%E0%A6%9A%E0%A6%B2%E0%A6%AC%E0%A7%87%E0%A6%87.mp3',
        demo: true
    },
    {
        id: '০৪',
        title: 'তোড়ী',
        path: 'https://www.sadhansarker.com/allfiles/201606/1467249301_%E0%A6%A1%E0%A7%8D%E0%A6%B0%E0%A6%BE%E0%A6%97%E0%A6%A8%E0%A7%87%E0%A6%B0%20%E0%A6%AC%E0%A6%B9%E0%A7%8D%E0%A6%A8%E0%A6%BF%20%E0%A6%AC%E0%A6%BF%E0%A6%B7.mp3',
        demo: true
    },
    {
        id: '০৫',
        title: 'জয় জয়ন্তী',
        path: 'https://www.sadhansarker.com/allfiles/201606/1467249524_%E0%A6%AC%E0%A6%BE%E0%A6%82%E0%A6%B2%E0%A6%BE%20%E0%A6%86%E0%A6%AE%E0%A6%BE%E0%A6%B0%20%E0%A6%A6%E0%A7%81%E0%A6%83%E0%A6%96.mp3',
        demo: true
    }
];

// export const audioDemoData = [
//     {
//         id: '০১',
//         title: 'HUA MAIN',
//         path: 'https://pagalnew.com/download128/43954',
//         demo: true
//     },
//     {
//         id: '০২',
//         title: 'CHALEYA SONG',
//         path: 'https://pagalnew.com/download128/43094',
//         demo: true
//     },
//     {
//         id: '০৩',
//         title: 'DIL JHOOM SONG',
//         path: 'https://pagalnew.com/download128/43878',
//         demo: true
//     },
//     {
//         id: '০৪',
//         title: 'HEERIYE SONG',
//         path: 'https://pagalnew.com/download128/43953',
//         demo: true
//     },
//     {
//         id: '০৫',
//         title: 'NOT RAMAIYA VASTAVAIYA SONG',
//         path: 'https://pagalnew.com/download128/43209',
//         demo: true
//     }
// ];

export default null;
