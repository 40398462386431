import React, { createContext, useContext, useLayoutEffect, useState } from 'react';

const LayoutContext = createContext();

export function useLayout() {
    return useContext(LayoutContext);
}

const screenSizeList = [
    320, // 100% - max:425px - xs
    575, // 540px  - sm
    768, // 720px - md
    992, // 960px  - lg
    1200, // 1140px - xl
    1400, // 1340px - xxl
    1600, // 1540px - 3xl
    2560 // 1540px - 4xl
];

const getSize = () => {
    const width = window.innerWidth;

    if (width < 320) {
        return 'xxs';
    }

    if (width < 576) {
        return 'xs';
    }

    if (width < 768) {
        return 'sm';
    }

    if (width < 992) {
        return 'md';
    }

    if (width < 1200) {
        return 'lg';
    }

    if (width < 1400) {
        return 'xl';
    }

    if (width < 1600) {
        return 'xxl';
    }

    if (width < 1800) {
        return '3xl';
    }

    return '4xl';
};

// xs: 0,
// sm: 576px,
// md: 768px,
// lg: 992px,
// xl: 1200px,
// xxl: 1400px

function LayoutProvider({ children }) {
    const [data, setData] = useState({
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
        layout: getSize(),
        screenSizeList
    });

    const select = () => {
        if (!children.length && !Array.isArray(children.props?.layout)) {
            return children;
        }

        if (!children.length && Array.isArray(children.props?.layout)) {
            for (let j = 0; j < children.props.layout.length; j += 1) {
                const layout = children.props?.layout[j];

                if (layout === data?.layout) {
                    return children;
                }
            }
        }

        for (let i = 0; i < children.length; i += 1) {
            const element = children[i];

            if (Array.isArray(element.props?.layout)) {
                for (let j = 0; j < element.props.layout.length; j += 1) {
                    const layout = element.props?.layout[j];

                    if (layout === data?.layout) {
                        return element;
                    }
                }
            }

            if (element.props.layout && element.props.layout === data?.layout) {
                return element;
            }
        }

        for (let i = 0; i < children.length; i += 1) {
            const element = children[i];

            if (element.props.layout === 'default') {
                return element;
            }
        }

        // --If Not Selected & No Default
        return children[0];
    };

    useLayoutEffect(() => {
        const updateSize = () => {
            setData({
                screenWidth: window.innerWidth,
                screenHeight: window.innerHeight,
                layout: getSize(),
                screenSizeList
            });
        };
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return <LayoutContext.Provider value={data}>{select()}</LayoutContext.Provider>;
}

export default LayoutProvider;
