/* eslint-disable import/no-extraneous-dependencies */
import { ButtonDark, ButtonMenu } from '../../../../configs/Buttons';
import { icons } from '../../../../configs/Icons';
import InstOption from '../../../utils/InstOption';
import { scrollToSection } from '../../../utils/Show';
import modifyUrl from '../../../utils/modifyUrl';
import { menuList } from './menuList';

function Menu() {
    const handleMenuClick = (item) => {
        scrollToSection(item?.path);
        if (item?.id === 6) {
            window.open(modifyUrl(item?.path), '_blank');
        }
        if (item?.id === 7) {
            window.open(item?.path, '_blank');
        }
    };

    return (
        <div className="header-menu w-[845px] flex gap-3.5 items-center justify-end">
            {menuList?.slice(0, 4)?.map((item) => (
                <ButtonDark
                    key={item?.id}
                    title={item?.title}
                    className="py-1.5 px-5 xl:px-6.5"
                    evOnClick={() => handleMenuClick(item)}
                />
            ))}

            <div className="h-full flex items-center">
                <ButtonMenu
                    evOnClick={null}
                    title={icons.iconMenuInactive}
                    className="py-1.5 px-6.5 w-[44px] h-[44px]"
                >
                    <InstOption>
                        {menuList?.slice(4, 16)?.map((item) => (
                            <button
                                type="button"
                                key={item?.id}
                                onClick={() => handleMenuClick(item)}
                            >
                                {item?.title}
                            </button>
                        ))}
                    </InstOption>
                </ButtonMenu>
            </div>
        </div>
    );
}

export default Menu;
