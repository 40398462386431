import AboutLeftCol from './components/AboutLeftCol';
import AboutRightCol from './components/AboutRightCol';

function AboutSection() {
    return (
        <div className="relative scroll-m-44 mt-5 md:mt-16.5" id="about">
            {/* background images */}
            <div className="w-full h-full 3xl:bg-harmonium bg-no-repeat absolute left-0 -z-10" />
            <div className="w-full h-full 3xl:bg-dhol bg-no-repeat bg-[bottom_left_10rem] absolute bottom-0 left-0 -z-10" />
            {/* background images */}

            <div className="section-container-sm flex flex-col-reverse md:flex-row gap-5 lg:gap-16.5 items-center lg:items-start justify-between bg-white">
                <AboutLeftCol />
                <AboutRightCol />
            </div>
        </div>
    );
}

export default AboutSection;
