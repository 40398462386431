import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonDark } from '../../../../../configs/Buttons';
import { shapeIcon } from '../../../../../configs/Icons';

function InfoRow({ title, value }) {
    return (
        <div className="flex gap-3 justify-between items-center text-dark-gold font-kalpurush text-sm md:text-base leading-normal">
            <p className="w-[35%]">{title}</p>:<p className="flex-1">{value}</p>
        </div>
    );
}

function Title({ title }) {
    return (
        <div className="flex flex-col items-center mb-3.5">
            <h2 className="text-center text-title-sm text-2xl">{title}</h2>
            <img src={shapeIcon.shapeOpera} alt="" />
        </div>
    );
}

function OperaLeftCol() {
    return (
        <div className="w-full h-full md:w-[50%] px-5 pb-5 pt-3 overflow-auto primary-scrollbar">
            <Title title="অভিযান" />
            <div className="mb-2.5">
                <InfoRow title="রচনা" value="কবি সুকান্ত ভট্টাচার্য্য" />
                <InfoRow title="সুর ও স্বরলিপি" value="সাধন সরকার" />
                <InfoRow title="প্রকাশকাল" value="কার্তিক, ১৩৮৪ বঙ্গাব্দ" />
                <InfoRow title="প্রকাশক" value="মিজানুর রহিম" />
                <InfoRow title="মঞ্চায়ন" value="স্কুল অব মিউজিক, খুলনা" />
                <InfoRow title="" value="ফেব্রুয়ারি, ১৯৭৬ খ্রিস্টাব্দ" />
                <InfoRow title="" value="স্কুল অব মিউজিক, খুলনা এর ছাত্র-ছাত্রী দ্বারা অভিনীত" />
            </div>

            <div className="text-center text-dark-gold font-kalpurush text-lg md:text-[22px] leading-7 mb-5">
                (দ্বিতীয় মহাযুদ্ধোত্তর বাংলাদেশের ভয়াবহ পঞ্চাশের মন্বন্তরের পটভূমিতে রচিত)
            </div>

            <div className="flex justify-center">
                <Link
                    target="_blank"
                    to="https://drive.google.com/file/d/1I5mXE9o7l_goModt2rab23xBGDVbECsf/view?usp=drive_link"
                >
                    <ButtonDark title="বিস্তারিত" />
                </Link>
            </div>
        </div>
    );
}

export default OperaLeftCol;
