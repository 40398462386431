import { Link } from 'react-router-dom';
import { audioPlayerIcon } from '../../../../configs/Icons';
import { useAudio } from '../../../context/AudioProvider';

function AudioPlayerCol5() {
    const { currentSong, isError } = useAudio();

    const isDownloadable = !isError && currentSong;

    return (
        <div className="flex items-center ">
            {/* <button type="button" className="w-[55px] h-[55px]">
                <img src={audioPlayerIcon.iconHeartLine} alt="heart" className="w-full h-full" />
            </button> */}

            <Link target={isDownloadable ? '_blank' : undefined} to={isDownloadable && currentSong}>
                <button type="button" className="w-[55px] h-[55px]">
                    <img
                        src={audioPlayerIcon.iconDownload}
                        alt="download"
                        className="w-full h-full"
                    />
                </button>
            </Link>
        </div>
    );
}

export default AudioPlayerCol5;
