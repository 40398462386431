/* eslint-disable import/no-extraneous-dependencies */
import { useLocation } from 'react-router-dom';
import { icons } from '../../../configs/Icons';
import { useAudio } from '../../context/AudioProvider';
import AudioPlayerCol1 from './components/AudioPlayerCol1';
import AudioPlayerCol2 from './components/AudioPlayerCol2';
import AudioPlayerCol3 from './components/AudioPlayerCol3';
import AudioPlayerCol4 from './components/AudioPlayerCol4';
import AudioPlayerCol5 from './components/AudioPlayerCol5';

function AudioPlayer() {
    const { pathname } = useLocation();
    const { showAudioPlayer, handleCloseAudioPlayer } = useAudio();

    if (!pathname?.includes('/home')) {
        return null;
    }
    return (
        <div
            className={`w-full bg-[#ffffffe6] shadow-[0px_-15px_30px_0px_rgba(166,88,35,0.05)] backdrop-blur-[5px] flex justify-center fixed bottom-0 left-0 ${
                !showAudioPlayer ? 'hidden' : ''
            }`}
        >
            <div className="w-full 2xl:max-w-[1440px] h-20 lg:h-[100px] flex gap-5 2xl:gap-10 justify-center relative px-5 2xl:px-0">
                <div className="flex flex-initial items-center justify-center">
                    <AudioPlayerCol1 />
                </div>
                <div className="lg:flex flex-initial items-center justify-center hidden min-w-[150px] 2xl:w-auto overflow-hidden">
                    <AudioPlayerCol2 />
                </div>
                <div className="lg:flex flex-1 items-center justify-center hidden min-w-[280px]">
                    <AudioPlayerCol3 />
                </div>
                <div className="lg:flex flex-initial items-center justify-center hidden">
                    <AudioPlayerCol4 />
                </div>
                <div className="lg:flex flex-initial items-center justify-center hidden w-auto">
                    <AudioPlayerCol5 />
                </div>
            </div>
            <button
                type="button"
                className="absolute -top-4 right-7.5 w-7 lg:w-[34px]"
                onClick={handleCloseAudioPlayer}
            >
                <img src={icons.iconClose} alt="close" className="w-full" />
            </button>
        </div>
    );
}

export default AudioPlayer;
