import VideoContent from './components/VideoContent';

function VideoSection() {
    return (
        <div>
            <div className="section-container-sm">
                <VideoContent />
            </div>
        </div>
    );
}

export default VideoSection;
