import { useAudio } from '../../../context/AudioProvider';

function AudioPlayerCol2() {
    const { currentSongTitle } = useAudio();

    return (
        <div className="overflow-hidden">
            <h1 className="text-2xl font-kalpurush font-normal text-ellipsis overflow-hidden whitespace-nowrap">
                {currentSongTitle}
            </h1>
        </div>
    );
}

export default AudioPlayerCol2;
