import { audioPlayerIcon } from '../../../../configs/Icons';
import { useAudio } from '../../../context/AudioProvider';

const AudioPlayerCol4 = () => {
    const { volumeChangeHandler, volume, muteHandler, isMuted } = useAudio();

    return (
        <div className="flex gap-2 items-center">
            <button type="button" className="w-[25px] h-[25px]" onClick={muteHandler}>
                <img
                    src={isMuted ? audioPlayerIcon.iconVolumeMute : audioPlayerIcon.iconVolume}
                    alt="volume"
                    className="w-full h-full"
                />
            </button>

            <input
                type="range"
                value={volume}
                onChange={volumeChangeHandler}
                min={0}
                max={1}
                step={0.01}
                className="w-auto h-full cursor-pointer"
                style={{
                    background: `
                    linear-gradient(
                    to right,
                    #C07F00 0%, #C07F00 ${(volume * 100).toFixed(2)}%, 
                    #FFF5D4 ${(volume * 100).toFixed(2)}%, 
                    #FFF5D4 100%
                    )
                    round
                    `
                }}
            />
        </div>
    );
};

export default AudioPlayerCol4;
