import React, { useEffect, useState } from 'react';
import { ButtonDarkV2 } from '../../../../../configs/Buttons';
import { shapeIcon } from '../../../../../configs/Icons';
import { songListDemoData } from '../../../../../data/songListDemoData';
import { useMenu } from '../../../../context/MenuProvider';
import InstOption from '../../../../utils/InstOption';
import getData from '../../../../utils/getData';
import { menuList } from '../../../header/component/menuList';
import SongListTable from './SongListTable';

function SectionTitle() {
    return (
        <div className="flex gap-2.5 md:gap-4.5 items-center mb-4 md:mb-5">
            <h2 className="text-title-lg">গান</h2>

            <div className="w-full h-[15px] md:h-[22px]">
                <img className="w-full h-full object-cover" src={shapeIcon.shapeTitle} alt="" />
            </div>
        </div>
    );
}

function ContentTitle({ title }) {
    return (
        <div className="flex gap-2.5 items-center justify-center mb-3 md:mb-6.5">
            <img src={shapeIcon.shapeLeft} alt="" />
            <h2 className="text-title-sm">{title}</h2>
            <img src={shapeIcon.shapeRight} alt="" />
        </div>
    );
}

function SongRightCol() {
    // songs tabs menu
    const tabs = menuList?.find?.((el) => el.id === 8)?.subMenu;

    const [songTab, setSongTab] = useState(
        tabs?.[0]?.subMenu ? tabs?.[0]?.subMenu?.[0] : tabs?.[0]
    );
    const { activeMenu } = useMenu();

    // console.log(getDataByType('song', songTab?.type));

    // song type select from menu tab
    const handleSongType = (ev, item) => {
        ev.stopPropagation();

        if (!item?.subMenu) {
            setSongTab(item);
        }
    };

    // set active menu when sidebar menu click
    useEffect(() => {
        if (activeMenu) {
            if (activeMenu?.path === 'song') {
                setSongTab(activeMenu);
            }
        }
    }, [activeMenu]);

    return (
        <div className="">
            <div className="md:h-[265px]">
                <SectionTitle />

                <div className="flex gap-2 md:gap-3.5 flex-wrap mb-2.5 md:mb-7.5">
                    {tabs?.map((item) => (
                        <ButtonDarkV2
                            key={item?.id}
                            title={item?.title}
                            evOnClick={(ev) => handleSongType(ev, item)}
                            className="px-[17px]"
                            active={songTab?.id === item?.id || songTab?.pId === item?.id}
                        >
                            {item?.subMenu && (
                                <InstOption>
                                    {item?.subMenu?.map((subItem) => (
                                        <button
                                            key={subItem?.id}
                                            type="button"
                                            onClick={(ev) => handleSongType(ev, subItem)}
                                        >
                                            {subItem?.title}
                                        </button>
                                    ))}
                                </InstOption>
                            )}
                        </ButtonDarkV2>
                    ))}
                </div>

                <ContentTitle title={songTab?.title} />
            </div>

            <SongListTable listArr={getData(songTab?.type) || songListDemoData} />

            {/* <div className="w-full text-right">
                <button type="button" className="text-title-sm text-lg">
                    আরো দেখুন...
                </button>
            </div> */}
        </div>
    );
}

export default SongRightCol;
