import AboutWrittenLeftCol from './components/AboutWrittenLeftCol';
import AboutWrittenRightCol from './components/AboutWrittenRightCol';

function AboutWrittenSection() {
    return (
        <div className="scroll-m-28">
            <div className="section-container-sm">
                <div className="xl:flex gap-14 xl:max-h-[600px] overflow-hidden">
                    <AboutWrittenLeftCol />
                    <AboutWrittenRightCol />
                </div>
            </div>
        </div>
    );
}

export default AboutWrittenSection;
