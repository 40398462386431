import { icons } from '../../../../configs/Icons';

function InfoRow({ title, value }) {
    return (
        <div className="flex justify-between ">
            <p className="text-left w-[40%] lg:w-[40%] md:whitespace-nowrap">{title}</p>
            <p className="font-josefin flex-1 text-right">{value}</p>
        </div>
    );
}

function PhoneRow({ title, value }) {
    return (
        <div className="flex justify-between ">
            <a href={`tel:${parseInt(value, 10)}`} className="text-left w-[40%] lg:w-[40%]">
                {title}
            </a>
            <a href={`tel:${parseInt(value, 10)}`} className="font-josefin text-right flex-1">
                {value}
            </a>
        </div>
    );
}

function MailRow({ title, value }) {
    return (
        <div className="flex justify-between gap-4">
            <a
                href={`mailto:${value}`}
                className="text-left w-[50%] sm:w-[40%] overflow-hidden text-ellipsis"
            >
                {title}
            </a>
            <a
                href={`mailto:${value}`}
                className="font-josefin text-right flex-1 overflow-hidden text-ellipsis"
            >
                {value}
            </a>
        </div>
    );
}

// function VideoRow({ title, path }) {
//     return (
//         <Link to={path} className="flex font-kalpurush text-lg text-[#FFF] font-normal mb-3.5">
//             <div className="w-[25px] h-[25px] mr-2.5">
//                 <img src={icons.iconYoutube} alt="youtube" className="w-full h-full" />
//             </div>

//             <div>{title}</div>
//         </Link>
//     );
// }

function Col1() {
    return (
        <div className="w-full lg:w-[41%]">
            <div className="w-[140px] sm:w-[165px] h-[60px] sm:h-[70px] mb-3 sm:mb-6">
                <img src={icons.iconLogo} alt="logo" className="w-full h-full" />
            </div>

            <div className="font-kalpurush text-sm sm:text-lg text-justify text-[#FFF] font-normal mb-2.5 sm:mb-6">
                <p className=" mb-2 sm:mb-2.5 text-justify">
                    বহুমুখী প্রতিভাধর সঙ্গীতাচার্য সাধন সরকার জন্ম গ্রহণ করেন খুলনা শহরে, ১৯২৮
                    খ্রিষ্টাব্দে; বাংলায় ১৩৩৫ বঙ্গাব্দের পৌষ মাসে। পিতা কেশব চন্দ্র দে, মাতা সুখদা
                    সুন্দরী দেবী। আদি নিবাস বৃহত্তর ঢাকার মানিকগঞ্জ জেলার দাসোরা গ্রামে।
                </p>
                <p className=" text-justify">
                    জীবনের সমস্ত অর্জনকে পিছনে ফেলে ১৯৯২ খ্রিষ্টাব্দের ১৫ জুন তথা ১৩৯৯ বঙ্গাব্দের ১
                    আষাঢ় সঙ্গীতাচার্য সাধন সরকার পরলোক গমন করেন।
                </p>
            </div>
        </div>
    );
}
function Col2() {
    return (
        <div className="flex-auto">
            <div className="mb-1 sm:mb-5">
                <h2 className="text-title-sm text-light-gold text-right">যোগাযোগ</h2>
            </div>

            <div className="font-kalpurush text-sm sm:text-lg text-[#FFF] font-normal mb-4 sm:mb-6">
                <InfoRow title="সুতপা দে সরকার" value="Sutapa De Sarker" />
                <InfoRow
                    title="৪৫, মির্জাপুর রোড (ইউসুফ রো)"
                    value="45, Mirzapur Road (Yousuf Row)"
                />
                <InfoRow title="খুলনা-৯১০০, বাংলাদেশ" value="Khulna-9100, Bangladesh" />

                <PhoneRow title="০১৫৫৮৩৩০৯৫০" value="01558330950" />
                <PhoneRow title="০১৯৯২০১২৮৬৯" value="01992012869" />
                <MailRow title="sutapa2011new@gmail.com" value="sutapa2011new@gmail.com" />
            </div>
        </div>
    );
}
// function Col3() {
//     return (
//         <div className="flex-initial">
//             <div className="mb-1.5 sm:mb-5">
//                 <h2 className="text-title-sm text-light-gold">ভিডিও</h2>
//             </div>

//             <VideoRow title="অনুষ্ঠান" />
//             <VideoRow title="বিশিষ্ট জনের বক্তব্য" />
//             <VideoRow title="প্রামাণ্যচিত্র" />
//         </div>
//     );
// }

function FooterRow1() {
    return (
        <div className="border-b-[2px] border-[#986D50] h-[calc(100%_-_64px)] sm:h-[calc(100%_-_70px)] pt-5 sm:pt-12 sm:pb-4">
            <div className="section-container mb-1 h-full">
                <div className="flex flex-col lg:flex-row items-stretch justify-between h-full gap-0 lg:gap-24 xl:gap-60 2xl:gap-48">
                    <Col1 />
                    <Col2 />
                    {/* <Col3 /> */}
                </div>
                <div className="font-kalpurush text-[19px] text-yellow text-center font-normal sm:whitespace-nowrap">
                    বাণিজ্যিক উদ্দেশ্যে এই ওয়েবসাইটের যেকোনো কন্টেন্ট ব্যবহার সম্পূর্নভাবে নিষিদ্ধ
                </div>
            </div>
        </div>
    );
}

export default FooterRow1;
