export const videoDemoData = [
    {
        id: 1,
        title: 'ওয়েব সাইট উদ্বোধনী অনুষ্ঠান (২৩/১২/২০১৬)-সপ্তর্ষি সাহা',
        youtubeLink: 'https://youtu.be/8IFmB1Q0Xcs',
        demo: true
    },
    {
        id: 2,
        title: 'ওয়েব সাইট উদ্বোধনী অনুষ্ঠান (২৩/১২/২০১৬)-সপ্তর্ষি সাহা',
        youtubeLink: 'https://youtu.be/8IFmB1Q0Xcs',
        demo: true
    }
];

export default null;
