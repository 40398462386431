import '../assets/css/style.css';
import RouteHandler from './RouteHandler';
import AudioProvider from './context/AudioProvider';
import MenuProvider from './context/MenuProvider';
import TemplateProvider from './features/template/TemplateProvider';
import ComposeProviders from './utils/ComposeProviders';

function App() {
    return (
        <ComposeProviders components={[TemplateProvider, MenuProvider, AudioProvider]}>
            <RouteHandler />
        </ComposeProviders>
    );
}

export default App;
