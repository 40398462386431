export const letterDemoData = [
    {
        id: '০১',
        title: 'এম এ রমজান',
        demo: true
    },
    {
        id: '০২',
        title: 'কবি আবুবকর সিদ্দিক',
        demo: true
    },
    {
        id: '০৩',
        title: 'নাজিম মাহমুদ',
        demo: true
    },
    {
        id: '০৪',
        title: 'ফয়জুল লতিফ চৌধুরী',
        demo: true
    },
    {
        id: '০৫',
        title: 'মনজুর আহমদ',
        demo: true
    },
    {
        id: '০৬',
        title: 'রফিকুল আলম',
        demo: true
    },
    {
        id: '০৭',
        title: 'শফি আহমেদ',
        demo: true
    },
    {
        id: '০৮',
        title: 'আবদুল আহাদ ও সনজিদা খাতুন',
        demo: true
    },
    {
        id: '০৯.',
        title: 'ওয়াহিদুল হক',
        demo: true
    },
    {
        id: '১০.',
        title: 'গোলাম সাকলায়েন',
        demo: true
    },
    {
        id: '১১.',
        title: 'দীপা বন্দ্যোপাধ্যায়',
        demo: true
    },
    {
        id: '১২.',
        title: 'মুক্তি মজুমদার',
        demo: true
    },
    {
        id: '১৩.',
        title: 'মির্জা সিরাজুল হক',
        demo: true
    },
    {
        id: '১৪.',
        title: 'রুহুল হক খোকা',
        demo: true
    },
    {
        id: '১৫.',
        title: 'শাহ মোঃ মোর্শেদ',
        demo: true
    },
    {
        id: '১৬.',
        title: 'সনৎ কুমার সাহা',
        demo: true
    }
];

export default null;
