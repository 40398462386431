import { useEffect, useState } from 'react';
import { ButtonDark } from '../../../../configs/Buttons';
import { useMenu } from '../../../context/MenuProvider';
import { menuList } from '../../header/component/menuList';
import ImagesRow from './components/ImagesRow';

function ImageGallerySection() {
    const tabs = menuList?.find?.((el) => el.id === 12)?.subMenu;
    const [activeTab, setActiveTab] = useState(tabs?.[0]);
    const [count, setCount] = useState(8);

    const { activeMenu } = useMenu();

    const handleShowMore = (num) => {
        setCount(num);
    };

    const handleTabClick = (item) => {
        setActiveTab(item);
        handleShowMore(8);
    };

    useEffect(() => {
        if (activeMenu) {
            if (activeMenu?.path === 'gallery') {
                setActiveTab(activeMenu);
            }
        }
    }, [activeMenu]);

    return (
        <div className="section-container">
            <h2 className="text-title-lg  mb-4 md:mb-6 text-center">ছবি ঘর</h2>

            <div className="flex gap-3.5 justify-center mb-3.5 md:mb-7.5">
                {tabs?.map((item) => (
                    <ButtonDark
                        key={item?.id}
                        title={item?.title}
                        evOnClick={() => handleTabClick(item)}
                        active={activeTab?.id === item?.id}
                        className="[&>span]:!text-[13px] [&>span]:md:!text-lg"
                    />
                ))}
            </div>

            <ImagesRow type={activeTab?.type} count={count} handleShowMore={handleShowMore} />
        </div>
    );
}

export default ImageGallerySection;
