import LetterLeftTable from './components/LetterLeftTable';
import LetterRightTable from './components/LetterRightTable';

function LetterSectionWeb({ listArr }) {
    return (
        <div className="flex gap-5 lg:gap-9">
            <LetterLeftTable listArr={listArr?.slice(0, 8)} />
            <LetterRightTable listArr={listArr?.slice(8, 16)} />
        </div>
    );
}

export default LetterSectionWeb;
