/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
import { useCallback, useEffect, useState } from 'react';
import LazyLoad from 'react-lazy-load';
import ImageViewer from 'react-simple-image-viewer';
import { ButtonLight } from '../../../../../configs/Buttons';
import getData from '../../../../utils/getData';

function ImagesRow({ type, count, handleShowMore }) {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    // when image viewer is shown, hide the header
    useEffect(() => {
        const header = document.getElementById('header');
        if (isViewerOpen) {
            header.style.display = 'none';
        } else {
            header.style.display = 'block';
        }
    }, [isViewerOpen]);

    const isShowMoreActive = count > 8;

    const imgDataByType = getData(type);

    const dataArr = imgDataByType?.length > 8 ? imgDataByType?.slice(0, count) : imgDataByType;

    return (
        <div>
            <div className="grid gap-2.5 md:gap-3 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 2xl:grid-cols-4 mb-2.5 md:mb-9">
                {dataArr?.map((item, index) => (
                    <div
                        role="button"
                        tabIndex={-1}
                        key={item?.id}
                        className="w-auto h-[177px] lg:h-[230px] xl:h-[280px] 2xl:h-[337px] relative"
                        onClick={() => openImageViewer(index)}
                    >
                        <LazyLoad className="w-auto h-full images">
                            <img
                                src={item?.imageUrl}
                                alt="gallery"
                                className="w-full h-full object-cover"
                            />
                        </LazyLoad>
                        <div className="absolute bottom-0 bg-[#693E21B2] w-full py-2">
                            <h3 className="text-sm md:text-lg font-sweet text-white text-center whitespace-nowrap text-ellipsis overflow-hidden px-2">
                                {item?.caption}
                            </h3>
                        </div>
                    </div>
                ))}

                {isViewerOpen && (
                    <ImageViewer
                        src={imgDataByType?.map((item) => item?.imageUrl)}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside
                        onClose={closeImageViewer}
                    />
                )}
            </div>

            {!isShowMoreActive && (
                <div className="flex justify-center w-full">
                    <ButtonLight
                        evOnClick={() => handleShowMore(imgDataByType?.length)}
                        title="আরও দেখুন"
                    />
                </div>
            )}
        </div>
    );
}

export default ImagesRow;
