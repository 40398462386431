/* eslint-disable react/no-unknown-property */
import { icons } from '../../../../configs/Icons';
import LayoutProvider from '../../../context/LayoutProvider';
import VisitorCounter from '../../../features/visitor-counter/VisitorCounter';

export function Counter({ value, color }) {
    return (
        <div className={`flex items-center rounded w-5 h-7.5 ${color}`}>
            <span className="w-full font-kalpurush text-lg text-white text-center font-normal">
                {value}
            </span>
        </div>
    );
}

function FooterRow2() {
    const convertToBanglaDigits = (y) => {
        let year = y;
        if (year === undefined) {
            // If no year parameter is passed, get the current year
            year = new Date().getFullYear();
        }

        const banglaDigits = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯'];

        return year
            .toString()
            .split('')
            .map((digit) => banglaDigits[parseInt(digit, 10)])
            .join('');
    };

    return (
        <div className="h-16 sm:h-[70px]">
            <div className="section-container h-full flex flex-col sm:flex-row justify-center sm:justify-between items-center">
                <p className="font-inter font-normal text-[13px] sm:text-lg text-white leading-7 whitespace-nowrap">
                    © {`${convertToBanglaDigits(2016)}-${convertToBanglaDigits()}`} সর্বস্বত্ব
                    সংরক্ষিত।
                </p>
                <p className="font-josefin font-normal text-sm sm:text-lg text-white leading-7 flex flex-nowrap items-center">
                    <span className="whitespace-nowrap">Made with</span>{' '}
                    <img src={icons.iconHeart} alt="heart" className="mx-1" /> By:{' '}
                    <span className="text-[#FFD95A] ml-1">BIKIRAN</span>
                </p>

                <LayoutProvider>
                    <div className="flex items-center" layout={['lg', 'xl', 'xxl', '3xl', '4xl']}>
                        <p className="font-kalpurush text-sm sm:text-lg text-white leading-7 font-normal mr-2.5">
                            দর্শনার্থীর সংখ্যা:
                        </p>

                        <VisitorCounter />
                    </div>
                </LayoutProvider>
            </div>
        </div>
    );
}

export default FooterRow2;
