import AudioLeftCol from './components/AudioLeftCol';
import AudioRightCol from './components/AudioRightCol';

function AudioSection() {
    return (
        <div>
            <div className="section-container-sm xl:flex gap-14 justify-between xl:max-h-[600px] overflow-hidden">
                <AudioLeftCol />

                <AudioRightCol />
            </div>
        </div>
    );
}

export default AudioSection;
