import { useRef } from 'react';

function InstOption({ /* showMenu, setShowMenu,  */ children, className = '' }) {
    const ref = useRef(null);

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (ref.current && !ref.current?.contains(event.target)) {
    //             setShowMenu(false);
    //         }
    //     };
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [setShowMenu]);

    // if (!showMenu) return null;

    return (
        <div
            className={`inst-option-cont absolute top-[50px] left-0 lg:left-auto lg:right-[0px] z-10 px-4 xl:px-5 py-2 bg-[#FFD95A] bg-patternDark bg-60 rounded-xl 2xl:min-w-[155px] hidden group-hover/inst:block ${className}`}
            ref={ref}
            style={{ boxShadow: '0px 4px 10px 0px rgba(166, 88, 35, 0.20)' }}
        >
            {children}
        </div>
    );
}

export default InstOption;
