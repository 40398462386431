/* eslint-disable import/no-extraneous-dependencies */
import LazyLoad from 'react-lazy-load';
import sectionImg from '../../../../../assets/images/section-b.jpg';

function SongLeftCol() {
    return (
        <div className="sticky-element">
            <div className="w-full xl:w-[500px] h-full mb-5 xl:mb-0">
                <LazyLoad>
                    <img src={sectionImg} alt="about" className="w-full" />
                </LazyLoad>
            </div>
        </div>
    );
}

export default SongLeftCol;
