import { Link } from 'react-router-dom';
import { lyricsDemoData } from '../../../../../data/lyricsDemoData';
import { convertNum } from '../../../../utils/Show';
import getData from '../../../../utils/getData';

function Tr({ listArr }) {
    if (!listArr?.length) {
        return (
            <tr>
                <td colSpan="3" className="text-left">
                    <span>কোনো তথ্য নেই</span>
                </td>
            </tr>
        );
    }
    return listArr?.map((item, index) => (
        <tr key={item?.id} className={item?.demo ? 'placeholder-mode' : ''}>
            <td className="text-center">
                <span>{convertNum(index + 1)}</span>
            </td>
            <td>
                <span>{item?.title}</span>
            </td>
            <td className="text-center">
                <Link target={item?.pdf && '_blank'} to={item?.pdf} className="underline">
                    দেখুন
                </Link>
            </td>
        </tr>
    ));
}

function LyricsCol() {
    return (
        <div className="w-full md:mb-0 md:h-[calc(100%_-_106px)] overflow-auto">
            {/* <h2 className="text-center text-title-sm mb-2 md:mb-6.5">গীতিআলেখ্য</h2> */}

            <div className="table-wrapper">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-center w-20">
                                <div className="flex items-center justify-center">
                                    <span className="hidden sm:block"> ক্র. নং</span>
                                    <span className="block sm:hidden"> নং</span>
                                </div>
                            </th>
                            <th>শিরোনাম</th>
                            <th className="pdf">
                                <span>পিডিএফ</span>
                                {/* <div className="flex justify-center">
                                    <img
                                        src={icons.iconPdf}
                                        alt="pdf"
                                        className="block 2xl:hidden w-[22px] h-[22px]"
                                    />
                                </div> */}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <Tr listArr={getData('OperaGitiallekho') || lyricsDemoData} />
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default LyricsCol;
