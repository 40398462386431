import OrganizationLeftCol from './components/OrganizationLeftCol';
import OrganizationRightCol from './components/OrganizationRightCol';

function OrganizationSection() {
    return (
        <div>
            <div className="section-container-sm xl:flex gap-14 justify-between xl:max-h-[600px] overflow-hidden">
                <OrganizationLeftCol />

                <OrganizationRightCol />
            </div>
        </div>
    );
}

export default OrganizationSection;
