import DifferentSectionContent from './components/DifferentSectionContent';

function DifferentSection() {
    return (
        <div>
            <div className="section-container-sm">
                <DifferentSectionContent />
            </div>
        </div>
    );
}

export default DifferentSection;
