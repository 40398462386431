import { useEffect, useState } from 'react';
import LazyLoad from 'react-lazy-load';
import sectionImg from '../../../../assets/images/section-d.jpg';
import { ButtonDark } from '../../../../configs/Buttons';
import { shapeIcon } from '../../../../configs/Icons';
import { useMenu } from '../../../context/MenuProvider';
import { menuList } from '../../header/component/menuList';
import LyricsCol from './components/LyricsCol';
import OperaCol from './components/OperaCol';

function SectionTitle() {
    return (
        <div className="flex gap-2.5 md:gap-4.5 items-center mb-4 md:mb-0 md:h-[90px]">
            <h2 className="text-title-lg whitespace-nowrap">গীতিনাট্য ও গীতিআলেখ্য</h2>

            <div className="w-full h-[15px] md:h-[22px]">
                <img className="w-full h-full object-cover" src={shapeIcon.shapeTitle} alt="" />
            </div>
        </div>
    );
}

function LyricsAndOperaSection() {
    const tabs = menuList?.find?.((el) => el.id === 9)?.subMenu;
    const [tabItem, setTabItem] = useState(tabs[0]);

    const { activeMenu } = useMenu();

    useEffect(() => {
        if (activeMenu) {
            if (activeMenu?.path === 'operaAndLyrics') {
                setTabItem(activeMenu);
            }
        }
    }, [activeMenu]);

    return (
        <div className="">
            <div className="section-container-sm flex flex-col xl:flex-row xl:gap-10 justify-between">
                <div className="sticky-element mb-8 xl:mb-0">
                    <div className="w-full xl:w-[500px] h-full">
                        <LazyLoad>
                            <img src={sectionImg} alt="about" className="w-full h-full" />
                        </LazyLoad>
                    </div>
                </div>

                <div className="">
                    <SectionTitle />
                    <div className="flex gap-[15px] mb-6 md:mb-0 md:h-[60px]">
                        {tabs.map((tab) => (
                            <ButtonDark
                                key={tab?.id}
                                title={tab?.title}
                                evOnClick={() => setTabItem(tab)}
                                active={tabItem?.id === tab?.id}
                                className="!h-9"
                            />
                        ))}
                    </div>

                    {tabItem?.type === 'opera' && <OperaCol />}
                    {tabItem?.type === 'lyrics' && <LyricsCol />}
                </div>
            </div>
        </div>
    );
}

export default LyricsAndOperaSection;
