import { useLocation } from 'react-router-dom';
import LayoutProvider from '../../context/LayoutProvider';
import HeaderSectionMobile from './HeaderSectionMobile';
import HeaderSectionWeb from './HeaderSectionWeb';

function HeaderSection() {
    const { pathname } = useLocation();

    if (!pathname?.includes('/home')) {
        return null;
    }
    return (
        <LayoutProvider>
            <HeaderSectionWeb layout="default" />
            <HeaderSectionMobile layout={['md', 'sm', 'xs', 'xxs']} />
        </LayoutProvider>
    );
}

export default HeaderSection;
