import FooterRow1 from './components/FooterRow1';
import FooterRow2 from './components/FooterRow2';

function FooterSection() {
    return (
        <div className="bg-dark-gold bg-patternDark bg-contain w-full h-auto" id="contact">
            <FooterRow1 />

            <FooterRow2 />
        </div>
    );
}

export default FooterSection;
