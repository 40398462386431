import { ButtonDarkV2 } from '../../../../../configs/Buttons';
import InstOption from '../../../../utils/InstOption';

function AudioTypeTabs({ handleSongType, item, songTab }) {
    return (
        <ButtonDarkV2
            key={item?.id}
            title={item?.title}
            evOnClick={(ev) => handleSongType(ev, item)}
            active={songTab?.id === item?.id || songTab?.pId === item?.id}
        >
            {item?.subMenu && (
                <InstOption>
                    {item?.subMenu?.map((subItem) => (
                        <button
                            key={subItem?.id}
                            type="button"
                            onClick={(ev) => handleSongType(ev, subItem)}
                        >
                            {subItem?.title}
                        </button>
                    ))}
                </InstOption>
            )}
        </ButtonDarkV2>
    );
}

export default AudioTypeTabs;
