import React, { useCallback, useEffect, useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import { shapeIcon } from '../../../../../configs/Icons';
import { convertNum } from '../../../../utils/Show';
import getData from '../../../../utils/getData';

function SectionTitle() {
    return (
        <div className="flex gap-4.5 items-center mb-4">
            <h2 className="text-title-lg whitespace-nowrap">প্রাপ্ত সম্মাননা</h2>

            <div className="w-full h-[22px]">
                <img className="w-full h-full object-cover" src={shapeIcon.shapeTitle} alt="" />
            </div>
        </div>
    );
}

function Tr({ listArr }) {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [imagesData, setImagesData] = useState([]);

    const openImageViewer = useCallback((data) => {
        setImagesData(data);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    // when image viewer is shown, hide the header
    useEffect(() => {
        const header = document.getElementById('header');
        if (isViewerOpen) {
            header.style.display = 'none';
        } else {
            header.style.display = 'block';
        }
    }, [isViewerOpen]);

    if (!listArr?.length) {
        return (
            <tr>
                <td colSpan="3" className="text-left">
                    <span>কোনো তথ্য নেই</span>
                </td>
            </tr>
        );
    }

    console.log(listArr);

    return listArr?.map((item, index) => (
        <tr key={item?.id} className={item.demo ? 'placeholder-mode' : ''}>
            <td className="text-center align-top">
                <span>{item?.id || convertNum(index + 1)}</span>
            </td>
            <td className="align-top">
                <div>
                    <span className="block">
                        <b>{item?.honor}</b>
                    </span>
                    <span>{item.year}</span>

                    <span className="block">{item?.type}</span>
                </div>
            </td>
            <td className="align-top">{item?.description}</td>
            <td className="align-top text-center">
                {item?.images?.length ? (
                    <button
                        type="button"
                        className="underline"
                        onClick={() => openImageViewer(item?.images)}
                    >
                        ছবি
                    </button>
                ) : null}

                <div className="honor-section-image-viewer">
                    {isViewerOpen && (
                        <ImageViewer
                            src={imagesData?.map((img) => img)}
                            currentIndex={currentImage}
                            disableScroll={false}
                            closeOnClickOutside
                            onClose={closeImageViewer}
                        />
                    )}
                </div>
            </td>
        </tr>
    ));
}

function Thead() {
    return (
        <thead>
            <tr>
                <th className="text-center w-[10%] xl:w-[13%]">
                    <div className="flex items-center justify-center">
                        <span className="hidden md:block"> ক্র. নং</span>
                        <span className="block md:hidden"> নং</span>
                    </div>
                </th>
                <th>সম্মাননা</th>
                <th>বিবরণ</th>
                <th className="w-[13%]">মন্তব্য</th>
            </tr>
        </thead>
    );
}

function HonorsLeftCol() {
    const listArr = getData('Honors');

    return (
        <div className="">
            <div className="md:h-[85px]">
                <SectionTitle />
            </div>

            <div
                className={`table-wrapper mb-2.5  overflow-auto ${
                    listArr ? 'md:h-[calc(100%_-_85px)]' : 'h-auto'
                }`}
            >
                <table className="table">
                    <Thead />

                    <tbody>
                        <Tr listArr={listArr} />
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default HonorsLeftCol;
