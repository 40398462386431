// logo
import iconLogo from '../assets/images/icons/icon-logo.svg';

// menu icons
import menu1Active from '../assets/images/icons/menu-1-active.svg';
import menu1 from '../assets/images/icons/menu-1.svg';
import menu2Active from '../assets/images/icons/menu-2-active.svg';
import menu2 from '../assets/images/icons/menu-2.svg';
import menu3Active from '../assets/images/icons/menu-3-active.svg';
import menu3 from '../assets/images/icons/menu-3.svg';
import menu4Active from '../assets/images/icons/menu-4-active.svg';
import menu4 from '../assets/images/icons/menu-4.svg';
import menu5Active from '../assets/images/icons/menu-5-active.svg';
import menu5 from '../assets/images/icons/menu-5.svg';

// shapes
import headerShape from '../assets/images/icons/header-shape.svg';
import shapeLeft from '../assets/images/shapes/shape-left.svg';
import shapeOpera from '../assets/images/shapes/shape-opera.svg';
import shapeRight from '../assets/images/shapes/shape-right.svg';
import sectionSeparator from '../assets/images/shapes/shape-separator.svg';
import shapeTitle from '../assets/images/shapes/shape-title.svg';

// icons
import iconMenuArrow from '../assets/images/icons/icon-arrow-menu.svg';
import iconClose from '../assets/images/icons/icon-close.svg';
import iconCorner from '../assets/images/icons/icon-corner.svg';
import iconHeart from '../assets/images/icons/icon-heart.svg';
import iconMenuActive from '../assets/images/icons/icon-menu-active.svg';
import iconMenuInactive from '../assets/images/icons/icon-menu-inactive.svg';
import iconMusic from '../assets/images/icons/icon-music.svg';
import iconPause from '../assets/images/icons/icon-pause.svg';
import iconPdf from '../assets/images/icons/icon-pdf.svg';
import iconPlay from '../assets/images/icons/icon-play.svg';
import iconWarning from '../assets/images/icons/icon-warning.svg';
import iconYoutubeSm from '../assets/images/icons/icon-youtube-sm.svg';
import iconYoutube from '../assets/images/icons/icon-youtube.svg';

// music player
import iconDownloadV2 from '../assets/images/icons/icon-download-v2.svg';
import iconDownload from '../assets/images/icons/icon-download.svg';
import iconHeartFill from '../assets/images/icons/icon-heart-fill.svg';
import iconHeartLineV2 from '../assets/images/icons/icon-heart-line-v2.svg';
import iconHeartLine from '../assets/images/icons/icon-heart-line.svg';
import iconNext from '../assets/images/icons/icon-next.svg';
import iconPauseLg from '../assets/images/icons/icon-pause-lg.svg';
import iconPlayLg from '../assets/images/icons/icon-play-lg.svg';
import iconPrev from '../assets/images/icons/icon-prev.svg';
import iconRepeatActive from '../assets/images/icons/icon-repeat-active.svg';
import iconRepeat from '../assets/images/icons/icon-repeat.svg';
import iconShuffle from '../assets/images/icons/icon-shuffle.svg';
import iconStopLg from '../assets/images/icons/icon-stop-lg.svg';
import iconVolumeMute from '../assets/images/icons/icon-volume-mute.svg';
import iconVolume from '../assets/images/icons/icon-volume.svg';

const loading = 'https://files.bikiran.com/loading.svg';
const loadingCircle = 'https://files.bikiran.com/loading-circle.svg';

export const icons = {
    iconLogo,
    headerShape,
    iconPlay,
    iconPause,
    iconHeart,
    iconYoutube,
    iconYoutubeSm,
    iconMenuInactive,
    iconMenuActive,
    iconCorner,
    iconClose,
    iconMenuArrow,
    iconPdf,
    iconMusic,
    iconWarning
};

export const images = {
    loading,
    loadingCircle
};

export const shapeIcon = {
    sectionSeparator,
    shapeLeft,
    shapeRight,
    shapeTitle,
    shapeOpera
};

export const audioPlayerIcon = {
    iconPlayLg,
    iconPauseLg,
    iconStopLg,
    iconHeartLine,
    iconHeartLineV2,
    iconHeartFill,
    iconDownload,
    iconDownloadV2,
    iconShuffle,
    iconRepeat,
    iconRepeatActive,
    iconPrev,
    iconNext,
    iconVolume,
    iconVolumeMute
};

export const menuIcon = {
    menu1,
    menu1Active,
    menu2,
    menu2Active,
    menu3,
    menu3Active,
    menu4,
    menu4Active,
    menu5,
    menu5Active
};

export default null;
