import { useRef } from 'react';
import { ButtonDark } from '../../../configs/Buttons';
import { icons } from '../../../configs/Icons';

function Buttons({ confirm, setConfirm, buttons }) {
    if (confirm?.buttons) {
        return buttons?.map((button) => (
            <div className="template-clickAction" key={buttons}>
                {button}
            </div>
        ));
    }
    return (
        <div className="flex flex-row justify-center items-center gap-4">
            <ButtonDark
                evOnClick={() => {
                    if (typeof confirm?.clickCancel === 'function') {
                        confirm?.clickCancel(confirm, setConfirm);
                    }

                    setConfirm(null);
                }}
                //   evOnClick={() => setConfirm(null)}
                title={confirm?.textCancel || 'No'}
            />

            <ButtonDark
                evOnClick={() => {
                    if (typeof confirm?.clickAction === 'function') {
                        confirm?.clickAction(confirm, setConfirm);
                    }
                }}
                active
                title={confirm?.textAction || 'Yes'}
            />
        </div>
    );
}

function ConfirmTemplate({ setConfirm, confirm }) {
    // setConfirm
    const ref = useRef();
    const { icon, text, buttons, showButton = true } = confirm || {};

    if (!confirm) {
        return null;
    }

    return (
        <div className="modal-box fixed top-0 left-0 px-5 sm:px-4 w-full h-full flex flex-col justify-center items-center z-50">
            <div className="modal-box-content w-auto sm:w-[500px] min-h-[200px] bg-white shadow-[0px_-15px_30px_0px_rgba(166,88,35,0.05)]  p-7 rounded-3xl text-center">
                <div className="flex flex-col justify-center items-center gap-5" ref={ref}>
                    <div className="w-12 h-12 text-warning">
                        {icon ? (
                            <img src={icon} alt="" className="block" />
                        ) : (
                            <img src={icons.iconWarning} alt="warning" />
                        )}
                    </div>

                    <div className="font-medium text-base">{text || '--'}</div>

                    {showButton && (
                        <div className="flex flex-row justify-center items-center gap-4">
                            <Buttons setConfirm={setConfirm} confirm={confirm} buttons={buttons} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ConfirmTemplate;
