// import dayjs from 'dayjs';

export const duration = (months) => {
    if (months < 12) {
        return `${months} Month`;
    }

    return `${months / 12} Year`;
};

// export function GetDate(timeStamp) {
//     if (!timeStamp) {
//         return null;
//     }
//     const dateFormat = dayjs(timeStamp * 1000).format(`DD ${`MMMM`.slice(0, 3)} YYYY`);
//     return dateFormat.toString() !== 'Invalid Date' ? dateFormat : '--';
// }

// export function GetTime(timeStamp) {
//     if (!timeStamp) {
//         return null;
//     }
//     const dateFormat = dayjs(timeStamp * 1000).format('h:mm:ss A');
//     return dateFormat.toString() || null;
// }

// export const getDayDiff = (timestamp) => {
//     const currentDate = dayjs();
//     const expireDate = dayjs(timestamp * 1000);

//     if (!timestamp) {
//         return null;
//     }

//     return expireDate?.diff(currentDate, 'd');
// };

// file size display
export const fileSizeDisplay = (bytes, text) => {
    const megabytes = bytes / (1024 * 1024);
    const gigabytes = megabytes / 1024;

    let usageText = `${megabytes.toFixed(2)} MB`;

    if (gigabytes >= 1) {
        usageText = `${gigabytes.toFixed(2)} GB`;
    }

    return <span>{`${usageText} ${text || 'Used'}` || '--'}</span>;
};

export function UpperCase(item) {
    return item?.charAt(0).toUpperCase() + item?.slice(1);
}

export const scrollToSection = (id) => {
    const section = document.getElementById(id);

    if (section) {
        if (id === 'contact') {
            const rect = section.getBoundingClientRect();
            const offset = window.pageYOffset || document.documentElement.scrollTop;
            const targetPosition = rect.bottom + offset - window.innerHeight + 1000;
            console.log(targetPosition);
            window.scrollTo({ top: targetPosition, behavior: 'smooth' });
        } else {
            const rect = section.getBoundingClientRect();
            const offset = window.pageYOffset || document.documentElement.scrollTop;
            const targetPosition = rect.top + offset - 80;
            window.scrollTo({ top: targetPosition, behavior: 'smooth' });
        }
    }
};

export const formatSeconds = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export function convertNum(input) {
    // Define a mapping of English digits to Bangla digits
    const digitMapping = {
        0: '০',
        1: '১',
        2: '২',
        3: '৩',
        4: '৪',
        5: '৫',
        6: '৬',
        7: '৭',
        8: '৮',
        9: '৯'
    };

    // Convert the input to a string and replace English digits with Bangla digits
    const inputString = input.toString();
    const banglaNumber = inputString.replace(/\d/g, (match) => digitMapping[match] || match);

    return banglaNumber;
}

export default null;
