import SongLeftCol from './components/SongLeftCol';
import SongRightCol from './components/SongRightCol';

function SongSection() {
    return (
        <div className="3xl:bg-harmonium-full bg-[right_5rem_bottom_15rem] bg-no-repeat bg-fixed">
            <div className="section-container-sm xl:flex gap-14 justify-between xl:max-h-[600px] overflow-hidden">
                <SongLeftCol />

                <SongRightCol />
            </div>
        </div>
    );
}

export default SongSection;
