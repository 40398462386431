/* eslint-disable import/no-extraneous-dependencies */
import LazyLoad from 'react-lazy-load';
import sectionImg from '../../../../assets/images/section-f.jpg';
import { shapeIcon } from '../../../../configs/Icons';
import SongBookContent from './components/SongBookContent';

const content1Arr = [
    {
        id: 1,
        title: 'প্রকাশক',
        value: 'জেবুন্নিসা মাহমুদ'
    },
    {
        id: 2,
        title: 'প্রকাশকাল',
        value: 'আশ্বিন, ১৩৭৫ বঙ্গাব্দ'
    }
];

const content2Arr = [
    {
        id: 1,
        title: 'প্রকাশক',
        value: 'মিজানুর রহিম'
    },
    {
        id: 2,
        title: '',
        value: 'স্কুল অব মিউজিক, খুলন'
    },
    {
        id: 3,
        title: 'প্রকাশকাল',
        value: 'অগ্রহায়ণ, ১৩৮০ বঙ্গাব্দ'
    }
];

const content3Arr = [
    {
        id: 1,
        title: 'প্রকাশক',
        value: 'মিজানুর রহিম'
    },
    {
        id: 2,
        title: '',
        value: 'স্কুল অব মিউজিক, খুলন'
    },
    {
        id: 3,
        title: 'প্রকাশকাল',
        value: 'বৈশাখ, ১৩৮৪ বঙ্গাব্দ'
    },
    {
        id: 4,
        title: '',
        value: 'বৈশাখ, ১৩৮৭ বঙ্গাব্দ'
    },
    {
        id: 5,
        title: '',
        value: 'ফাল্গুন, ১৩৯৪ বঙ্গাব্দ'
    }
];

const content4Arr = [
    {
        id: 1,
        title: 'প্রকাশক',
        value: 'মিজানুর রহিম'
    },
    {
        id: 2,
        title: '',
        value: 'স্কুল অব মিউজিক, খুলন'
    },
    {
        id: 3,
        title: 'প্রকাশকাল',
        value: ' কার্ত্তিক, ১৩৮৪ বঙ্গাব্দ'
    },
    {
        id: 4,
        title: '',
        value: 'অগ্রহায়ণ, ১৩৮৭ বঙ্গাব্দ'
    }
];

const content5Arr = [
    {
        id: 1,
        title: 'সম্পাদনা',
        value: 'সাজ্জাদুর রহিম পান্থ'
    },
    {
        id: 2,
        title: 'প্রকাশক',
        value: 'হুমায়ুন কবির ববি'
    },
    {
        id: 3,
        title: '',
        value: ' অহবায়ক'
    },
    {
        id: 4,
        title: '',
        value: 'প্রতিনিধি সংস্কৃতি সংস্থা, খুলনা'
    },
    {
        id: 5,
        title: 'প্রকাশকাল',
        value: '১ আষাঢ়, ১৪১২ বঙ্গাব্দ'
    },
    {
        id: 6,
        title: '',
        value: '১৫ জুন, ২০০৫ খ্রিস্টাব্দ'
    }
];
const content6Arr = [
    {
        id: 1,
        title: 'সম্পাদনা',
        value: 'সাজ্জাদুর রহিম পান্থ'
    },
    {
        id: 2,
        title: 'প্রকাশক',
        value: 'হুমায়ুন কবির ববি'
    },
    {
        id: 3,
        title: '',
        value: ' অহবায়ক'
    },
    {
        id: 4,
        title: '',
        value: 'প্রতিনিধি সংস্কৃতি সংস্থা, খুলনা'
    },
    {
        id: 5,
        title: 'প্রকাশকাল',
        value: '১ আষাঢ়, ১৪১৫ বঙ্গাব্দ'
    },
    {
        id: 6,
        title: '',
        value: '১৫ জুন, ২০০৮ খ্রিস্টাব্দ'
    }
];

function SectionTitle() {
    return (
        <div className="flex gap-7 items-center mb-4 md:h-[90px]">
            <h2 className="text-title-lg whitespace-nowrap">সুরারোপিত গানের বই</h2>

            <div className="w-full h-[22px]">
                <img className="w-full h-full object-cover" src={shapeIcon.shapeTitle} alt="" />
            </div>
        </div>
    );
}

function SongBookSection() {
    // const [showMore, setShowMore] = useState(false);

    return (
        <div className="">
            <div className="section-container-sm flex flex-col-reverse xl:flex-row xl:gap-14 justify-between xl:max-h-[600px] overflow-hidden">
                <div className="">
                    <SectionTitle />
                    <div className="flex flex-col border border-[#FEB423] rounded-[7px] mb-2 md:h-[calc(100%_-_106px)] overflow-auto primary-scrollbar">
                        <div className="sm:flex rounded-tl-[7px] rounded-tr-[7px]">
                            <SongBookContent
                                title="চেতনার সৈকতে"
                                contentArr={content1Arr}
                                pdfLink="https://drive.google.com/file/d/1Su9HmgKwu7CPNxnd0Kc9I-dHi8npo9LZ/view?usp=drive_link"
                                cName="sm:border-r border-b border-[#FEB423]"
                            />
                            <SongBookContent
                                title="ফসল ১ম খন্ড"
                                contentArr={content2Arr}
                                pdfLink="https://drive.google.com/file/d/1O1fzdoirgcqBW4-n7K0-QCaOxLDaBOvs/view?usp=drive_link"
                                cName="border-b border-[#FEB423]"
                            />
                        </div>
                        <div className="sm:flex rounded-bl-[7px] rounded-br-[7px]">
                            <SongBookContent
                                title="গঙ্গা ফড়িং"
                                contentArr={content3Arr}
                                pdfLink=""
                                cName="border-[#FEB423] sm:border-r border-b"
                            />
                            <SongBookContent
                                title="ফসল ৩য় খন্ড"
                                contentArr={content4Arr}
                                pdfLink="https://drive.google.com/file/d/1NROgfGrb-58-raLt8MKCCz9k9vMIL33e/view?usp=drive_link"
                                cName="border-b border-[#FEB423]"
                            />
                        </div>

                        <div className="sm:flex rounded-bl-[7px] rounded-br-[7px]">
                            <SongBookContent
                                title="সাধন সরকার স্মারক গ্রন্থ-১"
                                contentArr={content5Arr}
                                pdfLink="https://drive.google.com/file/d/16aq1k9o9qUMBAW27k9xWxFUDAP146SRj/view?usp=drive_link"
                                cName="sm:border-r border-[#FEB423]"
                            />
                            <SongBookContent
                                title="সাধন সরকার স্মারক গ্রন্থ-২"
                                contentArr={content6Arr}
                                pdfLink="https://drive.google.com/file/d/16aq1k9o9qUMBAW27k9xWxFUDAP146SRj/view?usp=drive_link"
                            />
                        </div>
                    </div>

                    {/* {!showMore && (
                        <div className="w-full text-right">
                            <button
                                type="button"
                                className="text-title-sm text-lg"
                                onClick={() => setShowMore((d) => !d)}
                            >
                                আরো দেখুন...
                            </button>
                        </div>
                    )} */}
                </div>

                <div className="sticky-element">
                    <div className="w-full xl:w-[500px] h-full mb-8 xl:mb-0">
                        <LazyLoad>
                            <img src={sectionImg} alt="sectionImg" className="w-full" />
                        </LazyLoad>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SongBookSection;
