import {
    aboutAfterLife,
    aboutInLife,
    audioOwnsingingAtulproshed,
    audioOwnsingingGonosangit,
    audioOwnsingingUcchangosangit,
    honors,
    imagesCeremony,
    imagesFammily,
    imagesRecognition,
    letters,
    literatureChora,
    literatureDrama,
    literaturePoetry,
    literatureStory,
    operaGitallekho,
    organization,
    others,
    songAdhunik,
    songBhaktigitiDolpurnima,
    songBhaktigitiDurgapuja,
    songBhaktigitiJhulonjatra,
    songBhaktigitiJonmastomi,
    songBhaktigitiKalikapuja,
    songBhaktigitiRashpurnima,
    songBhaktigitiSborisbotipuja,
    songChorjapod,
    songEkushe,
    songFolk,
    songOthersBideshigan,
    songOthersBorshoboron,
    songOthersBouddhopurnima,
    songOthersHamdh,
    songOthersJibananandadas,
    songOthersKazinazrulislam,
    songOthersKrishnachandramojumder,
    songOthersModhusudan,
    SongOthersRabindranathtagore,
    songOthersSukantabhattacharya,
    songPatriotic,
    songRhymes,
    songUcchangoSangitBanglaKhayel,
    songUcchangoSangitRagprodhangan,
    videoDocumentary,
    videoOccasion,
    videoSpeech
} from '../../data/data';

const getData = (type) => {
    // aboutWritten
    if (type === 'AboutInlife') return aboutInLife;
    if (type === 'AboutAfterlife') return aboutAfterLife;

    // literature
    if (type === 'LiteratureChora') return literatureChora;
    if (type === 'LiteraturePoetry') return literaturePoetry;
    if (type === 'LiteratureStory') return literatureStory;
    if (type === 'LiteratureDrama') return literatureDrama;

    if (type === 'OperaGitiallekho') return operaGitallekho;

    // organization
    if (type === 'Organization') return organization;

    // honors
    if (type === 'Honors') return honors;

    // songs
    if (type === 'SongPatriotic') return songPatriotic;
    if (type === 'SongEkushe') return songEkushe;
    if (type === 'SongFolk') return songFolk;
    if (type === 'SongAdhunik') return songAdhunik;
    if (type === 'SongChorjapod') return songChorjapod;
    if (type === 'SongUcchangoSangitRagprodhangan') return songUcchangoSangitRagprodhangan;
    if (type === 'SongUcchangoSangitBanglaKhayel') return songUcchangoSangitBanglaKhayel;
    if (type === 'SongRhymes') return songRhymes;
    if (type === 'SongBhaktigitiDurgapuja') return songBhaktigitiDurgapuja;
    if (type === 'SongBhaktigitiKalikapuja') return songBhaktigitiKalikapuja;
    if (type === 'SongBhaktigitiSborisbotipuja') return songBhaktigitiSborisbotipuja;
    if (type === 'SongBhaktigitiDolpurnima') return songBhaktigitiDolpurnima;
    if (type === 'SongBhaktigitiJonmastomi') return songBhaktigitiJonmastomi;
    if (type === 'SongBhaktigitiRashpurnima') return songBhaktigitiRashpurnima;
    if (type === 'SongBhaktigitiJhulonjatra') return songBhaktigitiJhulonjatra;
    if (type === 'SongOthersRabindranathtagore') return SongOthersRabindranathtagore;
    if (type === 'SongOthersKajiNajrulIslam') return songOthersKazinazrulislam;
    if (type === 'SongOthersJibananandadas') return songOthersJibananandadas;
    if (type === 'SongOthersModhusudan') return songOthersModhusudan;
    if (type === 'SongOthersSukantabhattacharya') return songOthersSukantabhattacharya;
    if (type === 'SongOthersKrishnachandramojumder') return songOthersKrishnachandramojumder;
    if (type === 'SongOthersBouddhopurnima') return songOthersBouddhopurnima;
    if (type === 'SongOthersBorshoboron') return songOthersBorshoboron;
    if (type === 'SongOthersHamdh') return songOthersHamdh;
    if (type === 'SongOthersBideshigan') return songOthersBideshigan;

    // letters
    if (type === 'Letters') return letters;

    // audio
    if (type === 'AudioOthers') return [];
    if (type === 'AudioOwnsingingUcchangosangit') return audioOwnsingingUcchangosangit;
    if (type === 'AudioOwnsingingGonosangit') return audioOwnsingingGonosangit;
    if (type === 'AudioOwnsingingAtulproshed') return audioOwnsingingAtulproshed;

    // video
    if (type === 'VideoOccasion') return videoOccasion;
    if (type === 'VideoSpeech') return videoSpeech;
    if (type === 'VideoDocumentary') return videoDocumentary;

    // others
    if (type === 'Others') return others;

    // images
    if (type === 'ImagesRecognition') return imagesRecognition;
    if (type === 'ImagesCeremony') return imagesCeremony;
    if (type === 'ImagesFammily') return imagesFammily;

    return null;
};

export default getData;
