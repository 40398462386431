import { Link } from 'react-router-dom';
import { ButtonDark } from '../../../../../configs/Buttons';
import { shapeIcon } from '../../../../../configs/Icons';
import SongBookInfoRow from './SongBookInfoRow';

function Title({ title }) {
    return (
        <div className="flex flex-col items-center mb-3.5">
            <h2 className="text-center text-title-sm text-2xl">{title}</h2>
            <img src={shapeIcon.shapeOpera} alt="" />
        </div>
    );
}

function SongBookContent({ title, contentArr, pdfLink, cName }) {
    return (
        <div className={`w-full sm:w-1/2 px-5 pb-5 pt-3 ${cName}`}>
            <Title title={title} />
            <div className="mb-2.5">
                <div className="mb-3">
                    {contentArr?.map((item) => (
                        <SongBookInfoRow key={item?.id} title={item?.title} value={item?.value} />
                    ))}
                    <SongBookInfoRow title="প্রকাশকাল" value="আশ্বিন, ১৩৭৫ বঙ্গাব্দ" />
                </div>
                {pdfLink?.length ? (
                    <div className="flex justify-center">
                        <Link target="_blank" to={pdfLink}>
                            <ButtonDark title="বিস্তারিত" />
                        </Link>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default SongBookContent;
