import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonDark } from '../../../../../configs/Buttons';

function AboutLeftCol() {
    return (
        <div>
            <h2 className="text-gold text-4xl font-normal font-sweet mb-3 sm:mb-4">সাধন সরকার </h2>

            <div className="font-kalpurush text-sm md:text-base text-primary-light mb-7 ">
                <p className="mb-2.5 text-justify">
                    <span className="text-primary">
                        ব্যারিকেড বেয়নেট বেড়াজাল, পাকে পাকে তড়পায় সমকাল...
                    </span>
                    স্বাধীন বাংলা বেতার কেন্দ্র থেকে প্রচারিত গানটির জ্বালাময়ী সুরের আবেদন সেই সময়
                    এদেশের প্রতিটি মুক্তিকামী বাঙালীর মনে আগুন ঝরাতো। এই আগুন ঝরা গানের সংগ্রামী
                    সুরকারই সঙ্গীতাচার্য সাধন সরকার। একই সঙ্গে অসামান্য সঙ্গীত শিল্পী ও সফল সুরকার
                    খুব বেশী দেখা যায় না। বিশেষ করে উচ্চাঙ্গ সঙ্গীতের ক্ষেত্রে তাঁর দক্ষতা,
                    রবীন্দ্র-নজরুল সব গানেই দ্বিধাহীন পারঙ্গমতা। পাশাপাশি গণসঙ্গীত, দেশের গান,
                    একুশের গান, আধুনিক গান, ভক্তিগীতি, ছড়া গান প্রভৃতিতে আবেদনময় অসাধারণ সুর সৃষ্টি।
                    মূলতঃ গুনী সঙ্গীত শিল্পী ও অনন্য সুরকার এ দুইয়ের সমন্বয় ঘটেছিল ব্যক্তি সাধন
                    সরকারের মধ্যে।
                </p>
                <p className="mb-2.5 text-justify">
                    বহুমুখী প্রতিভাধর সঙ্গীতাচার্য সাধন সরকার জন্মগ্রহণ করেন খুলনা শহরে, ১৯২৮
                    খ্রিস্টাব্দে; বাংলায় ১৩৩৫ বঙ্গাব্দের পৌষ মাসে। পিতা কেশব চন্দ্র দে, মাতা সুখদা
                    সুন্দরী দেবী। আদি নিবাস বৃহত্তর ঢাকার মানিকগঞ্জ জেলার দাসোরা গ্রামে। শৈশবেই তাঁর
                    অকাল পিতৃ বিয়োগ ঘটে। বিধবা মাতার স্নেহে ও তত্বাবধানে কিশোর সাধন সরকারের লেখাপড়া
                    চলতে থাকে। ১৯৪৬ খ্রিস্টাব্দে খুলনা শহরের বি কে ইউনিয়ন ইনস্টিটিউশন স্কুল থেকে
                    তিনি ম্যাট্রিক পাশ করেন। তৎকালীণ রাজনৈতিক অস্থিরতা ও অর্থনৈতিক অস্বচ্ছলতা এ দুটি
                    কারনে মূলতঃ তাঁর লেখাপড়া আর এগোয়নি। তবে তিনি ছিলেন স্বশিক্ষিত, যার প্রমাণ তাঁর
                    লেখা গল্প, নাটক, কবিতা পড়লেই বোঝা যায়। পড়াশুনা না থাকলে এই বিষয়গুলি কলমের
                    মাধ্যমে বেরিয়ে আসতো না। লেখালেখির অভ্যাসটি তাঁর একটি গুণ বা বৈশিষ্ট।
                </p>
                <p className="block md:hidden lg:block text-justify">
                    ছোটবেলা থেকেই পারিবারিক পরিবেশের ভেতর দিয়েই তাঁর সঙ্গীতের সাথে পরিচয়। মায়ের সাথে
                    কৃষ্ণের আরাধনায় ভক্তিগীতিতে গলা মেলাতেন এবং খোল বাজাতেন। এভাবেই সঙ্গীতের প্রতি
                    অনুরাগী হয়ে পড়েন। ১৯৪৭ এর পর তিনি কিশোরী মোহন বন্দ্যোপাধ্যায় এর কাছে বাঁশী
                    বাজানো শিখতে শুরু করেন। সেই সঙ্গে ওস্তাদ শামসুদ্দীন এবং ওস্তাদ শাহাজাহানের কাছে
                    কন্ঠ সঙ্গীতেও তাঁর সাধনা শুরু হয়। এরপর ওস্তাদ রইসউদ্দীনের কাছে সঙ্গীত শিক্ষা
                    নেন। প্রকৃত অর্থে ওস্তাদ রইসউদ্দীন ছিলেন তাঁর সঙ্গীত গুরু। এছাড়াও তিনি কালিদাস
                    চট্টোপাধ্যায় এবং সুকুমার মিত্রের কাছেও তালিম নেন।
                </p>
            </div>

            <div>
                <Link
                    target="_blank"
                    to="https://drive.google.com/file/d/1DwDXjF2xPly1rZL742Upqt2rpwuMugFH/view?usp=drive_link"
                >
                    <ButtonDark title="বিস্তারিত" evOnClick={null} />
                </Link>
            </div>
        </div>
    );
}

export default AboutLeftCol;
