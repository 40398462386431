import { shapeIcon } from '../../../../../configs/Icons';
import OperaLeftCol from './OperaLeftCol';
import OperaRightCol from './OperaRightCol';

// function InfoRow({ title, value }) {
//     return (
//         <div className="flex gap-5 justify-between items-center text-dark-gold font-kalpurush text-base leading-normal">
//             <p className="w-[35%]">{title}</p>:<p className="flex-1">{value}</p>
//         </div>
//     );
// }

export function Title({ title }) {
    return (
        <div className="flex flex-col items-center mb-3.5">
            <h2 className="text-center text-title-sm text-xl">{title}</h2>
            <img src={shapeIcon.shapeOpera} alt="" />
        </div>
    );
}

function OperaCol() {
    return (
        <div className="w-full flex flex-col md:flex-row border divide-y md:divide-y-0 md:divide-x divide-[#FEB423] border-[#FEB423] rounded-[7px] mb-3 md:mb-0 md:h-[calc(100%_-_170px)] overflow-auto primary-scrollbar">
            {/* <h2 className="text-center text-title-sm mb-2 md:mb-6.5">গীতিনাট্য</h2> */}

            <OperaLeftCol />
            <OperaRightCol />
        </div>
    );
}

export default OperaCol;
