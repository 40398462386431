import { icons } from '../../../../../configs/Icons';

function YoutubePlayer({ closeClick, id }) {
    return (
        <div className="relative">
            <div className="fixed w-[77%] lg:w-[66%] h-[50%] md:h-[60%] lg:h-[75%] top-[55%] xl:top-[58%] 2xl:top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2 z-50">
                <iframe
                    src={`https://www.youtube.com/embed/${id}`}
                    frameBorder="0"
                    allowFullScreen
                    title="YouTube Video"
                    className="w-full h-full bg-white"
                />
                <button type="button" className="absolute -top-5 -right-3.5" onClick={closeClick}>
                    <img className="w-7 h-7 sm:w-8 sm:h-8" src={icons.iconClose} alt="close" />
                </button>
            </div>
        </div>
    );
}

export default YoutubePlayer;
