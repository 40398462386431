import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonDark } from '../../../../../configs/Buttons';
import { shapeIcon } from '../../../../../configs/Icons';
import { aboutDemoData } from '../../../../../data/aboutDemoData';
import { useMenu } from '../../../../context/MenuProvider';
import { convertNum } from '../../../../utils/Show';
import getData from '../../../../utils/getData';
import { menuList } from '../../../header/component/menuList';

function SectionTitle() {
    return (
        <div className="flex items-center gap-2 mb-4 md:mb-5">
            <h2 className="text-title-lg text-[20px] sm:text-[25px] md:text-[32px] whitespace-nowrap">
                সাধন সরকারকে নিয়ে প্রকাশনা ও লেখা
            </h2>

            <div className="w-full">
                <img className="w-full h-[22px] object-cover" src={shapeIcon.shapeTitle} alt="" />
            </div>
        </div>
    );
}

function ContentTitle({ title }) {
    return (
        <div className="flex gap-2.5 items-center justify-center mb-6.5">
            <img src={shapeIcon.shapeLeft} alt="" />
            <h2 className="text-title-sm">{title}</h2>
            <img src={shapeIcon.shapeRight} alt="" />
        </div>
    );
}

function Tr({ listArr }) {
    if (!listArr?.length) {
        return (
            <tr>
                <td colSpan="3" className="text-left">
                    <span>কোনো তথ্য নেই</span>
                </td>
            </tr>
        );
    }
    return listArr?.map((item, index) => (
        <tr key={item?.id} className={item?.demo ? 'placeholder-mode' : ''}>
            <td className="text-center">
                <span>{convertNum(index + 1)}</span>
            </td>
            <td>
                <span>{item?.title}</span>
            </td>
            <td>
                <span>{item?.info}</span>
            </td>
            <td className="text-center">
                {item?.pdf && (
                    <Link target={item?.pdf && '_blank'} to={item?.pdf} className="underline">
                        দেখুন
                    </Link>
                )}
            </td>
        </tr>
    ));
}

function AboutWrittenRightCol() {
    const tabs = menuList?.find?.((el) => el.id === 4)?.subMenu;

    const [tabItem, setTabItem] = useState(tabs[0]);

    const { activeMenu } = useMenu();

    useEffect(() => {
        if (activeMenu) {
            if (activeMenu?.path === 'aboutWritten') {
                setTabItem(activeMenu);
            }
        }
    }, [activeMenu]);

    const listArr = getData(tabItem?.type) || aboutDemoData;

    return (
        <div className="">
            <div className="md:h-[190px]">
                <SectionTitle />

                <div className="flex gap-[15px] mb-8">
                    {tabs.map((tab) => (
                        <ButtonDark
                            key={tab?.id}
                            title={tab?.title}
                            evOnClick={() => setTabItem(tab)}
                            active={tabItem?.id === tab?.id}
                        />
                    ))}
                </div>

                <ContentTitle title={tabItem?.title} />
            </div>

            <div
                className={`table-wrapper mb-2.5 overflow-auto ${
                    listArr?.length ? 'md:h-[calc(100%_-_190px)]' : 'h-auto'
                }`}
            >
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-center w-20">
                                <div className="flex items-center justify-center">
                                    <span className="hidden sm:block"> ক্র. নং</span>
                                    <span className="block sm:hidden"> নং</span>
                                </div>
                            </th>
                            <th className="w-[120px] sm:w-[165px]">
                                গ্রন্থ / পত্রিকা প্রকাশনার শিরোনাম
                            </th>
                            <th>সম্পর্কিত তথ্য</th>
                            <th className="pdf">
                                <span>পিডিএফ</span>
                                {/* <div className="flex justify-center">
                    <img
                      src={icons.iconPdf}
                      alt="pdf"
                      className="block lg:hidden w-[22px] h-[22px]"
                    />
                  </div> */}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <Tr listArr={listArr} />
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AboutWrittenRightCol;
